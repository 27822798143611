.notification__page {
  padding: 0;
  background-color: $color-light-ghost-white;
  &__header {
    padding: 25px 25px 15px;
    .breadcrumb {
      padding-bottom: 7px;
      li:not(:first-child) {
        color: $color-gray;
      }
    }
    .title{
      padding-top: 7px;
      color: $color-primary-dark;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
  }

  &__tab{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-detail{
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 25px;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 100%;
      border-bottom: 1px solid $color-light-light-gray;
      padding: 0 25px;
      button {
        background-color: transparent;
        border: none;
        padding: 10px;
        color: $color-darkLiver;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-bottom: 2px solid transparent;
        &.active {
          font-weight: 500;
          line-height: normal;
          color: $color-gray;
          border-bottom: 2px solid $color-primary-dark;
        }
        .unread-count{
          color: $color-white;
          margin: 0;
          background: $color-primary;
          max-width: 50px;
          min-width: 24px;
          padding: 4px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          border-radius: 50%;
        }
      }
    }
    
  }
  &__container {
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-card{
      padding: 16px;
      display: flex;
      flex-direction: row;
      border-radius: 8px;
      gap: 16px;      
      border: 1px solid $color-light-light-gray;
      &-image{
        span{
          font-size: 24px;
        }
      }
      &-content{
        display: inline-flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        &-title{
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: $color-primary-dark;
          margin: 0;
          display: inline-flex;
          gap: 8px;
          align-items: center;
          span:last-child{
            font-size: 13px;
            font-weight: 400;
            color: $color-oldSilver;
          }
          .warning{
            color: $color-light-red;
          }
        }
        &-body {
          color: $color-darkLiver;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: inline-flex;
          gap: 4px;
          align-items: center;
          span{
            font-weight: 500;
            color: $color-gray;
            text-transform: capitalize;
          }
          .updated_on{
            font-size: 13px;
            font-weight: 400;
            color: $color-oldSilver;
          }
        }
        &-btn{
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          display: flex;
          min-height: 40px;
          align-items: center;
          gap: 8px;
          padding-top: 8px;
          &-view{
            padding: 8px 16px;
            color: $color-primary;
            border: 1px solid $color-primary;
            border-radius: 8px;
            background-color: $color-white;
          }
          &-markread{
            padding: 8px 16px;
            color: $color-primary-dark;
            border: none;
            background-color: $color-white;
          }
        }
      }
    }
    .unread{
      background-color: $color-white;
    }
    .read{
      background-color: #EBFCFF;
    }
  }
}
