$fontInter: "Inter";
$fontSans: "Public Sans";

$baseFont: var(--default-font);

$color-white: var(--color-white);
$color-black: var(--color-black);
$color-black1: var(--color-black1);
$color-light-black: var(--color-light-black);
$color-blue: var(--color-blue);
$color-blue-2: var(--color-blue-2);
$color-blue-3: var(--color-blue-3);
$color-blue-4: var(--color-blue-4);
$color-purple: var(--color-purple);
$color-purple-dark: var(--color-purple-dark);
$color-light-purple: var(--color-light-purple);
$color-red: var(--color-red);
$color-light-red: var(--color-light-red);
$color-light-red-1: var(--color-light-red-1);
$color-light-red-2: var(--color-light-red-2);
$color-orange: var(--color-orange);
$color-orange-light: var(--color-orange-light);
$color-orange-lighter: var(--color-orange-lighter);
$color-yellow: var(--color-yellow);
$color-light-yellow: var(--color-light-yellow);
$color-light-yellow-1: var(--color-light-yellow-1);
$color-light-yellow-2: var(--color-light-yellow-2);
$color-dark-green: var(--color-dark-green);
$color-dark-green-1: var(--color-dark-green-1);
$color-light-green: var(--color-light-green);
$color-lighter-green: var(--color-lighter-green);
$color-lighter-green4: var(--color-lighter-green4);
$color-gray: var(--color-gray);
$color-light-gray: var(--color-light-gray);
$color-light-gray-1: var(--color-light-gray-1);
$color-light-gray-2: var(--color-light-gray-2);
$color-light-gray-3: var(--color-light-gray-3);
$color-light-gray-4: var(--color-light-gray-4);
$color-light-gray-5: var(--color-light-gray-5);
$color-light-gray-6: var(--color-light-gray-6);
$color-light-gray-7: var(--color-light-gray-7);
$color-light-gray-8: var(--color-light-gray-8);
$color-light-gray-9: var(--color-light-gray-9);
$color-light-gray-10: var(--color-light-gray-10);
$color-light-gray-11: var(--color-light-gray-11);
$color-light-gray-bg: var(--color-light-gray-bg);
$color-dark-gray: var(--color-dark-gray);
$color-dark-gray-1: var(--color-dark-gray-1);
$color-dark-gray-2: var(--color-dark-gray-2);
$color-dark-gray-3: var(--color-dark-gray-3);
$color-dark-gray-4: var(--color-dark-gray-4);
$color-dark-gray-5: var(--color-dark-gray-5);
$color-dark-gray-6: var(--color-dark-gray-6);
$color-darkLiver: var(--color-darkLiver);
$color-silver: var(--color-silver);
$color-oldSilver: var(--color-oldSilver);
$color-light-light-gray: var(--color-light-light-gray);
$color-light-light-gray-1: var(--color-light-light-gray-1);
$color-light-light-gray-2: var(--color-light-light-gray-2);
$color-light-light-gray-3: var(--color-light-light-gray-3);
$color-light-light-gray-4: var(--color-light-light-gray-4);
$color-light-ghost-white: var(--color-light-ghost-white);
$color-primary: var(--color-primary);
$color-primary-1: var(--color-primary-1);
$color-primary-280: var(--color-primary-280);
$color-primary-260: var(--color-primary-260);
$color-primary-240: var(--color-primary-240);
$color-primary-220: var(--color-primary-220);
$color-primary-dark: var(--color-primary-dark);
$color-primary-dark-180: var(--color-primary-dark-180);
$color-primary-dark-160: var(--color-primary-dark-160);
$color-primary-dark-140: var(--color-primary-dark-140);
$color-primary-dark-120: var(--color-primary-dark-120);
$color-text-primary: var(--color-text-primary);
$color-primary-light-1: var(--color-primary-light-1);
$color-primary-light-2: var(--color-primary-light-2);
$color-primary-light-3: var(--color-primary-light-3);
