.user-profile {
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    ul.breadcrumb {
      padding: 10px 16px 0px;
      list-style: none;
      li {
        display: inline;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
        padding-right: 8px;
      }
      li + li:before {
        padding: 8px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
        content: "❯";
        padding-right: 15px;
      }
      li:hover {
        color: #01447e;
        cursor: pointer;
      }
    }
    .edit-btn {
      display: block;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      color: $color-white;
      background-color: $color-primary-dark;
      border: none;
      width: 79px;
      height: 35px;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
  .flex-data {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    column-gap: 24px;
    margin: 0 0 24px;
    .user-photo {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      row-gap: 14px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
      }
      .userNull-image {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        font-size: 40px;
        color: $color-white;
      }
      .user-profile__image__status {
        display: inline-flex;
        flex-direction: row;
        column-gap: 4px;
        padding: 2px 4px;
        border-radius: 12px;
        &.icon-away {
          background-color: $color-light-ghost-white;
        }
        &.icon-active {
          background-color: $color-lighter-green4;
        }
        &.icon-busy {
          background-color: $color-light-red-2;
        }
        b {
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .user-data {
      p {
        margin: 0 0 12px;
      }
      .userName {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-gray;
        .userName-at {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-darkLiver;
          text-transform: lowercase;
        }
        &-status{
          font-size: 13px;
          font-weight: 500;
          .active{
            color: $color-white;
            background-color: $color-light-green;
            border-radius: 5px;
            padding: 2px 4px;
          }
          .inactive{
            color: $color-white;
            background-color: $color-light-red;
            border-radius: 5px;
            padding: 2px 4px;
          }
        }
      }
      .userTeam {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
      }
      .userEmail {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-blue;
      }
      .userRegion-timezone {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-darkLiver;
        .timezone {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-darkLiver;
        }
      }
    }
  }
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 78px;
    .user-data-btns {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 32px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      border-bottom: 1px solid $color-light-light-gray;
      button {
        background-color: $color-white;
        border: none;
        padding: 10px;
        color: $color-darkLiver;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 2px solid transparent;
        &.active {
          font-weight: 500;
          line-height: normal;
          color: $color-gray;
          border-bottom: 2px solid $color-gray;
        }
      }
    }
    .user-overview {
      padding-left: 25px;
      &__table {
        border: none;
        border-collapse: collapse;
        td {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-darkLiver;
          padding: 0 6px 16px 0;
        }
        &--data {
          font-weight: 500 !important;
          color: $color-gray !important;
        }
      }
    }
  }
  .employee-edit-form {
    padding: 0px 65px 28px;
    max-width: 1285px;
    margin-bottom: 150px;
    .heading {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-black;
      margin: 0 0 0 124px;
    }
    .flex-data {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 24px;
      margin: 0 0 24px;
      padding: 15px 0 54px 0;
      border-bottom: 1px solid $color-light-light-gray;
      .user-photo {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        row-gap: 14px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50px;
        }
        .user-profile__image__status {
          display: inline-flex;
          flex-direction: row;
          column-gap: 4px;
          padding: 2px 4px;
          border-radius: 12px;
        }
      }
      .user-data {
        display: flex;
        flex-direction: row;
        column-gap: 50px;
        align-items: baseline;
        justify-content: flex-start;
        .user-overview {
          &__table {
            border: none;
            border-collapse: collapse;
            margin-top: 16px;
            &--data {
              font-size: 15px !important;
              font-weight: 500 !important;
              color: $color-black !important;
              .timezone {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-darkLiver;
              }
            }
            td {
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              padding: 0 6px 16px 0;
              vertical-align: top;
              &:first-child {
                width: 120px;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
    .edit-form {
      margin: 0 0 0 127px;
      .edit-form-heading {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 25px;
      }
      .form-group {
        max-width: 750px;
        .flex-formData {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          column-gap: 20px;
          .flex-formData-row {
            width: 365px;
            .custom-dropdwon {
              position: relative;
              border-radius: 6px;
              border: 1px solid $color-light-light-gray;
              background: $color-light-ghost-white;
              height: 42px;
              margin: 0 0 20px 0;
              padding: 13px 15px 13px 12px;
              .input-field {
                color: $color-darkLiver;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                flex-direction: row;
                overflow: hidden;
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                justify-content: flex-start;
                align-items: center;
                max-width: 365px;
                .input-values {
                  max-width: 365px;
                  input {
                    line-height: inherit;
                    border: none;
                    margin-left: 5px;
                    background: transparent;
                    padding: 0;
                    width: calc(15ch + 5px);
                    font-size: smaller;
                    cursor: pointer;
                    border: none;
                    &:focus-visible {
                      outline: unset;
                    }
                  }
                }
                .arrow {
                  text-align: center;
                  pointer-events: none;
                  margin: 0 0 0 5px;
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  -ms-transform: rotate(180deg);
                  transform: rotate(180deg);
                  cursor: pointer;
                  & svg {
                    box-sizing: border-box;
                    width: 16px;
                    vertical-align: middle;
                    height: 16px;
                  }
                }
              }
              .popup-content {
                position: absolute;
                top: 49px;
                left: 0;
                border: 1px solid #ccc;
                width: 365px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                background: #fff;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                max-height: 300px;
                overflow: auto;
                z-index: 9;
                padding: 12px;
                border-radius: 6px;
                .popup-heading {
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  color: var(--color-gray);
                  margin: 0 0 12px 0;
                  padding-bottom: 12px;
                  border-bottom: 1px solid $color-light-gray;
                }
                .list-option {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  column-gap: 8px;
                  margin: 0 0 16px 0;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-gray;
                }
                .setTime-flex {
                  display: flex;
                  flex-direction: row;
                  column-gap: 16px;
                  width: 100%;
                  position: relative;
                  .setTime {
                    display: inline-flex;
                    flex-direction: row;
                    width: 150px;
                    border-radius: 12px;
                    background: $color-white;
                    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
                    margin: 0 0 8px 2px;
                    align-items: center;
                  }
                  .clock-icon {
                    display: block;
                    margin: 2px 0px 2px 8px;
                  }
                  input {
                    width: 138px;
                    padding: 12px;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 4px 0 4px 10px;
                    height: 35px;
                    border: none;
                  }
                  .time-button {
                    background-color: $color-light-light-gray;
                    color: $color-primary-dark;
                    width: 42px;
                    height: 42px;
                    padding: 10px;
                    border-radius: 0 12px 12px 0;
                    cursor: pointer;
                  }
                }
              }
            }
            .label-name {
              display: block;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              margin: 0 0 6px;
              sup {
                color: $color-light-red;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                vertical-align: -3px;
              }
            }
            .react-dropdown-select {
              border-radius: 6px;
              border: 1px solid $color-light-light-gray;
              background: $color-light-ghost-white;
              height: 42px;
              margin: 0 0 20px 0;
              padding: 13px 15px 13px 12px;
              &.departmentList {
                .react-dropdown-select-input {
                  &::placeholder {
                    color: $color-light-red;
                  }
                }
              }
              .react-dropdown-select-content {
                color: $color-darkLiver;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                flex-direction: row;
                max-width: 315px;
                overflow: hidden;
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                justify-content: flex-start;
                .react-dropdown-select-option {
                  background: transparent;
                  color: $color-darkLiver;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  white-space: nowrap;
                  margin: 0;
                  &:first-child {
                    padding-left: 0px;
                  }
                }
              }
              &.custom-dropdownRenderer {
                .react-dropdown-select-option {
                  position: relative;
                  display: inline;
                  &::before {
                    position: absolute;
                    content: ",";
                    right: 0px;
                    bottom: 0;
                  }
                  &:nth-last-child(2)::before {
                    content: "";
                  }
                  .react-dropdown-select-option-remove {
                    display: none;
                  }
                }
                .react-dropdown-select-dropdown {
                  overflow: visible;
                  border-radius: 6px;
                  .dropdwonList-main {
                    padding: 12px;
                    .dropdwonList-head {
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      color: $color-gray;
                      margin: 0 0 12px 0;
                      padding-bottom: 12px;
                      border-bottom: 1px solid $color-light-gray;
                    }
                    .dropdwonLists {
                      overflow: auto;
                      max-height: 200px;
                      padding: 0px 12px 12px 12px;
                      margin: 0 22px 0 10px;
                      &.single {
                        margin: 0;
                        padding: 0;
                      }
                      &::-webkit-scrollbar {
                        width: 8px;
                        height: 4px;
                        background-color: $color-light-light-gray;
                        border-radius: 10px;
                      }
                      &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px
                          $color-light-light-gray;
                        border-radius: 10px;
                        background-color: $color-light-light-gray;
                      }
                      &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                        background-color: $color-oldSilver;
                      }
                      .dropdwonList-subhead,
                      .dropdwonLists-label {
                        margin: 0 0 16px 0;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $color-gray;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        column-gap: 8px;
                        &.disabled,
                        &:disabled {
                          pointer-events: none;
                          cursor: not-allowed;
                          opacity: 0.5;
                        }
                        &:last-child {
                          margin: 0 0 0 0;
                        }
                        &.sub-list {
                          margin-left: 8px;
                        }
                        label {
                          cursor: pointer;
                        }
                        .checkbox-img {
                          width: 16px;
                          height: 100%;
                        }
                      }
                      .setTime-flex {
                        display: flex;
                        flex-direction: row;
                        column-gap: 16px;
                        width: 100%;
                        position: relative;
                        .setTime {
                          display: inline-flex;
                          flex-direction: row;
                          width: 150px;
                          column-gap: 10px;
                          border-radius: 12px;
                          background: $color-white;
                          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
                          margin: 0 0 8px 2px;
                          align-items: center;
                        }
                        .clock-icon {
                          display: block;
                          margin: 2px 0px 2px 8px;
                        }
                        input {
                          width: 62px;
                          padding: 10px;
                          font-size: 15px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          margin: 4px 0;
                          height: 35px;
                          border: none;
                        }
                        .time-button {
                          background-color: $color-light-light-gray;
                          color: $color-primary-dark;
                          width: 42px;
                          height: 42;
                          padding: 10px;
                          border-radius: 0 12px 12px 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .flex-formData-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          column-gap: 20px;
          .cancel-btn {
            display: block;
            width: 100px;
            height: 38px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            border: none;
            line-height: normal;
            color: $color-white;
            border-radius: 35.202px;
            border: 1px solid $color-light-light-gray;
            color: $color-darkLiver;
            padding: 8px;
            text-align: center;
            cursor: pointer;
          }
          .submit-btn {
            display: block;
            min-width: 85px;
            text-align: center;
            height: 38px;
            font-size: 15px;
            font-style: normal;
            padding: 2px 16px;
            font-weight: 400;
            border: none;
            line-height: normal;
            color: $color-white;
            border-radius: 35.202px;
            background: $color-primary-dark;
            position: relative;
            &.loading {
              background: $color-primary-dark;
              padding-right: 35px;
            }
            &.loading::before {
              content: "";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }
          }
          @keyframes spin {
            to {
              transform: rotate(359deg);
            }
          }
          @keyframes grow {
            to {
              width: 14px;
              height: 14px;
              margin-top: -8px;
              right: 13px;
            }
          }
        }
      }
    }
  }
}
