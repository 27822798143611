.reginDropDwonList {
  width: 100%;
  border-radius: 6px !important;
  border: 1px solid $color-light-light-gray !important;
  background: $color-light-ghost-white !important;
  margin: 0 0 0px 0;
  padding: 13px 15px 13px 12px !important;
  .react-dropdown-select-content {
    color: $color-darkLiver;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: row;
    min-width: 250px;
    overflow: hidden;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    justify-content: flex-start;
  }
  .react-dropdown-select-dropdown-handle svg {
    vertical-align: -4px;
    width: 21px;
    height: 21px;
    margin: 0;
  }
  .react-dropdown-select-option {
    background: transparent;
    color: $color-darkLiver;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    margin: 0;
    position: relative;
    &::before {
      position: absolute; 
      content: ",";
      right: 0px;
      bottom: 0;
    }
    &:nth-last-child(2)::before {
      content: "";
    }
    .react-dropdown-select-option-remove {
      display: none;
    }
  }
  .react-dropdown-select-dropdown {
    overflow: visible;
    border-radius: 6px;
    width: 100%;
    z-index: 1100;
    top: 50px;
    .dropdwonList-main {
      padding: 12px 12px;
      background-color: $color-white;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      .dropdwonList-head {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-gray;
        margin: 0 0 12px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid $color-light-gray;
      }
      .dropdwonLists {
        overflow: auto;
        max-height: 200px;
        padding: 0px 12px 0px 12px;
        // margin: 0 8px 10px 0px;
        position: relative;
        &.single {
          margin: 0;
          padding: 0;
        }
        &::-webkit-scrollbar {
          width: 8px;
          height: 4px;
          background-color: $color-light-light-gray;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
          border-radius: 10px;
          background-color: $color-light-light-gray;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
          background-color: $color-oldSilver;
        }
        .error-show {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
          padding: 0;
          color: $color-light-red;
          text-align: center;
        }
        .dropdwonList-subhead,
        .dropdwonLists-label {
          margin: 0 0 16px 0;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-gray;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          column-gap: 8px;
          &.disabled,
          &:disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: .5;
          }
          &:last-child {
            margin: 0 0 0 0;
          }
          &.sub-list {
            margin-left: 8px;
          }
          .lable-field {
            display: inline-flex;
            align-items: flex-start;
            flex-direction: column;
            row-gap: 0px;
            padding: 0;
            margin: 0 0 0 0px;
            label {
              cursor: pointer;
            }
            .mail-field {
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              overflow: hidden;
              max-width: 250px;
              text-overflow: ellipsis;
            }
          }
          .checkbox-img {
            width: 16px;
            height: 100%;
          }
        }
      }
    }
  }
  &.member-list {
    .dropdwonList-main {
      padding: 20px 20px 20px 12px;
      .dropdwonLists-label {
        /*display: inline-block !important;*/
        &.disabled,
        &:disabled {
          pointer-events: none;
          cursor: not-allowed;
          opacity: .5;
        }

        .lable-field {
          margin: 0 0 0 8px !important;
        }
      }
      .flex-btn {
        float: right;
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        margin: 12px 0 0;
        .reset-btn {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 35.202px;
          color: $color-primary-dark;
          border: 1px solid $color-primary-dark;
          background: $color-white;
          height: 31px;
          width: 71px;
        }
        .includemember-btn {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 35.202px;
          color: $color-white;
          background: $color-primary-dark;
          height: 31px;
          width: 71px;
        }
      }
    }
  }
}
