$icomoon-font-family: "Orion" !default;
$icomoon-font-path: "fonts" !default;

$icon-star-active: unquote('"\\e900"');
$icon-time_empty-path1: unquote('"\\e95d"');
$icon-time_empty-path2: unquote('"\\e95e"');
$icon-time_empty-path3: unquote('"\\e95f"');
$icon-edit-horizontal-icons: unquote('"\\e960"');
$icon-upload_file: unquote('"\\e95c"');
$icon-description: unquote('"\\e95b"');
$icon-certificate-check-path1: unquote('"\\e901"');
$icon-certificate-check-path2: unquote('"\\e902"');
$icon-certificate-check-path3: unquote('"\\e903"');
$icon-certificate-check-path4: unquote('"\\e904"');
$icon-certificate-check-path5: unquote('"\\e905"');
$icon-certificate-check-path6: unquote('"\\e906"');
$icon-time-path1: unquote('"\\e907"');
$icon-time-path2: unquote('"\\e908"');
$icon-time-path3: unquote('"\\e909"');
$icon-timer-path1: unquote('"\\e90a"');
$icon-timer-path2: unquote('"\\e90b"');
$icon-timer-path3: unquote('"\\e90c"');
$icon-timer-path4: unquote('"\\e90d"');
$icon-list-box-path1: unquote('"\\e90e"');
$icon-list-box-path2: unquote('"\\e90f"');
$icon-list-box-path3: unquote('"\\e910"');
$icon-list-box-path4: unquote('"\\e911"');
$icon-list-box-path5: unquote('"\\e912"');
$icon-download_file: unquote('"\\e948"');
$icon-check-list: unquote('"\\e913"');
$icon-ordered-list: unquote('"\\e914"');
$icon-bullet-list: unquote('"\\e915"');
$icon-underline: unquote('"\\e916"');
$icon-strike: unquote('"\\e917"');
$icon-italic: unquote('"\\e918"');
$icon-bold: unquote('"\\e919"');
$icon-status-in-progress-path1: unquote('"\\e91a"');
$icon-status-in-progress-path2: unquote('"\\e91b"');
$icon-status-in-progress-path3: unquote('"\\e91c"');
$icon-status-in-progress-path4: unquote('"\\e91d"');
$icon-status-pause-path1: unquote('"\\e91e"');
$icon-status-pause-path2: unquote('"\\e91f"');
$icon-status-pause-path3: unquote('"\\e920"');
$icon-users: unquote('"\\e921"');
$icon-access-pending: unquote('"\\e922"');
$icon-access-request: unquote('"\\e923"');
$icon-warning: unquote('"\\e924"');
$icon-remainder: unquote('"\\e925"');
$icon-kanban: unquote('"\\e926"');
$icon-ss-calendar: unquote('"\\e92c"');
$icon-unsupported-file: unquote('"\\e927"');
$icon-archive-file: unquote('"\\e928"');
$icon-doc-file: unquote('"\\e929"');
$icon-image-file: unquote('"\\e92a"');
$icon-pdf-file: unquote('"\\e92b"');
$icon-xls-file: unquote('"\\e92d"');
$icon-remove-cross-path1: unquote('"\\e92e"');
$icon-remove-cross-path2: unquote('"\\e92f"');
$icon-yet-to-launch: unquote('"\\e930"');
$icon-ir-tools-only: unquote('"\\e931"');
$icon-failure-cross-path1: unquote('"\\e932"');
$icon-failure-cross-path2: unquote('"\\e933"');
$icon-ir-medium: unquote('"\\e934"');
$icon-ir-plus: unquote('"\\e935"');
$icon-ir-premium: unquote('"\\e936"');
$icon-ir-basic: unquote('"\\e937"');
$icon-success-tick-path1: unquote('"\\e938"');
$icon-success-tick-path2: unquote('"\\e939"');
$icon-unchecked: unquote('"\\e93a"');
$icon-checked: unquote('"\\e93b"');
$icon-search: unquote('"\\e93c"');
$icon-away: unquote('"\\e93d"');
$icon-busy: unquote('"\\e93e"');
$icon-active: unquote('"\\e93f"');
$icon-wave-path1: unquote('"\\e940"');
$icon-wave-path2: unquote('"\\e941"');
$icon-wave-path3: unquote('"\\e942"');
$icon-wave-path4: unquote('"\\e943"');
$icon-wave-path5: unquote('"\\e944"');
$icon-wave-path6: unquote('"\\e945"');
$icon-wave-path7: unquote('"\\e946"');
$icon-euroland-icon: unquote('"\\e947"');
$icon-euroland-icon-primary-path1: unquote('"\\e949"');
$icon-euroland-icon-primary-path2: unquote('"\\e94a"');
$icon-euroland-icon-primary-path3: unquote('"\\e94b"');
$icon-euroland-icon-primary-path4: unquote('"\\e94c"');
$icon-Chat_Circle: unquote('"\\e94d"');
$icon-Mail: unquote('"\\e94e"');
$icon-Phone: unquote('"\\e94f"');
$icon-Bell_Notification-path1: unquote('"\\e950"');
$icon-Bell_Notification-path2: unquote('"\\e951"');
$icon-Bell_Notification-path3: unquote('"\\e952"');
$icon-Bell_Notification-path4: unquote('"\\e953"');
$icon-attachment: unquote('"\\e954"');
$icon-close: unquote('"\\e955"');
$icon-dots-horizontal-triple: unquote('"\\e956"');
$icon-chevron-thin-down: unquote('"\\e957"');
$icon-chevron-thin-right: unquote('"\\e958"');
$icon-calendar: unquote('"\\e959"');
$icon-switch: unquote('"\\e95a"');
$icon-profile: unquote('"\\e961"');
$icon-up-arrow: unquote('"\\ea3a"');
$icon-down-arrow: unquote('"\\ea3e"');

