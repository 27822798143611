.home__page {
  margin: 0;
  // padding: 0px 0 0 10px;
  transition: padding 0.3s;
  &--theme-dark {
    background: $color-primary-dark-180;
  }
  &__header {
    padding: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--color-light-light-gray);
    /* background-color: var(--color-light-ghost-white);*/
    margin: 0;
    display: flex;
    justify-content: space-between;
    min-height: 60px;
    @media (max-width: 1366px) {
      min-height: 50px;
    }
    &-tabs {
      column-gap: 14px;
      list-style-type: none;
      padding: 0;
      margin: 0 0 0 18px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 8px 0;
      & li {
        color: $color-darkLiver;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 15px;
        position: relative;
        user-select: none;
        &.active {
          font-style: normal;
          color: $color-primary-dark;
          font-weight: 500;
        }
      }
    }
    &-tabs-center {
      column-gap: 14px;
      list-style-type: none;
      padding: 0;
      margin: 0 0 0 18px;
      display: flex;
      flex-direction: row;
      align-items: self-end;
      justify-content: center;
      padding: 0 0 0px 0;
      width: 100%;
      & li {
        color: $color-darkLiver;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 15px;
        position: relative;
        user-select: none;
        min-width: 150px;
        .icon-chevron-thin-left:before {
          content: "\e958";
        }
        .icon-chevron-thin-left {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
        }
        &.active {
          font-style: normal;
          color: $color-primary-dark;
          border: 1px solid $color-light-light-gray;
          font-weight: 500;
          padding: 8px 15px;
          border-radius: 10px 10px 0 0;
          border-bottom: none;
          margin: 0 0 -1px;
          z-index: 100;
          background: $color-light-ghost-white;
        }
      }
    }
    &-options {
      list-style-type: none;
      padding: 0;
      margin: 0 0 0px 18px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      column-gap: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      height: 42px;
      & button {
        background: $color-primary-dark;
        color: $color-white;
        min-width: 100px;
        // height: 36px;
        border-radius: 8px;
        padding: 12px;
        border: none;
        margin: 4px 0;
      }
    }
  }
  .board-server-error {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    text-align: center;
    margin: 30vh auto auto;
  }
  .display-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    margin: 18px 24px 15px 17px;
    @media (max-width: 1366px) {
      margin: 18px 10px 10px 17px;
    }
    & .flex-col {
      display: inline-flex;
      flex-direction: row;
      column-gap: 10px;
      justify-content: space-between;
      align-items: center;

      .edit-board,
      .archiveBoard-btn,
      .filter-btn {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: $color-primary-dark-120;
        border: none;
        // background-color: $color-white;
        // padding: 0px;
        line-height: 13px;
        &:disabled {
          opacity: 0.5;
        }
      }
      .filter-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        // background-color: $color-white;
        // padding: 0px;
        line-height: 13px;
        button.update-status {
          width: 40px;
          height: 40px;
          padding: 8px;
          border-radius: 30px;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
      .filter-flex.active-btn {
        .filter-btn {
          background: $color-primary-dark;
          color: $color-white;
          .update-status img.filterIcon {
            filter: brightness(0) invert(1);
          }
        }
      }
      .showFilterCount {
        display: inline-flex;
        flex-direction: row;
        column-gap: 5px;
        justify-content: space-between;
        color: $color-primary-dark;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.73px;
        text-align: left;
        .icon-close {
          background-color: $color-light-red;
          font-size: 8px;
          padding: 2px 4px 0px;
          line-height: 11px;
          border-radius: 25px;
          color: $color-white;
          cursor: pointer;
        }
      }
      .avatars {
        display: flex;
        list-style-type: none;
        margin: auto;
        padding: 0px 7px 0px 0px;
        align-items: center;
        .display-icon-btn{
          background: unset;
          border: unset;
          padding: 0;
          
        }
        .display-icon {
          display: inline-flex;
          align-items: center;
          border-radius: 50%;
          // width: 18px;
          // height: 18px;
          overflow: hidden;
          fill: var(--color-white);
          border: 1px solid $color-light-light-gray;
          vertical-align: middle;
          margin-left: -5px;
          &:first-child {
            margin-left: 0;
          }
          .user-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            border-radius: 50%;
            color: $color-white;
          }
        }
        .avatars__others {
          background-color: $color-light-light-gray;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 30px;
          font-size: 12px;
          font-weight: 400;
          line-height: 12.1px;
          color: $color-primary-dark;
          border-radius: 50%;
        }
      }
    }
    .board-name {
      color: $color-primary-dark;
      padding: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 10px;
      label {
        // background: rgb(255, 186, 87);
        padding: 3px 6px;
        font-size: 14px;
        font-weight: 500;
        color: $color-white;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        gap: 6px;
        .icon-ir-medium {
          font-size: 12px;
          cursor: pointer;
          &:before {
            color: $color-darkLiver;
          }
        }
        .defaultBoard {
          font-size: 12px;
          background-color: #dcf5ff;
          color: $color-primary;
          padding: 4px 6px;
          border-radius: 24px;
          display: inline-flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;
          &::before {
            content: " ";
            background: #9bd8ef;
            padding: 4px;
            font-size: 2px;
            border-radius: 24px;
          }
        }
      }
      .background-masker {
        background-color: $color-white;
        position: absolute;
      }

      .btn-divide-left {
        top: 0;
        left: 25%;
        height: 100%;
        width: 5%;
      }

      @keyframes placeHolderShimmer {
        0% {
          background-position: -800px 0;
        }
        100% {
          background-position: 800px 0;
        }
      }

      .animated-background {
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: #f6f7f8;
        background: linear-gradient(
          to right,
          #eeeeee 8%,
          #bbbbbb 18%,
          #eeeeee 33%
        );
        background-size: 800px 104px;
        height: 25px;
        position: relative;
        display: inline-block;
        width: 200px;
      }

      .static-background {
        background-color: #f6f7f8;
        background-size: 800px 104px;
        height: 70px;
        position: relative;
        margin-bottom: 20px;
      }

      .shared-dom {
        width: 800px;
        height: 110px;
      }
      .sub-rect {
        border-radius: 100%;
        width: 70px;
        height: 70px;
        float: left;
        margin: 20px 20px 20px 0;
      }
      .pure-background {
        background-color: #eee;
      }
    }
    .select-edit {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .react-dropdown-select.switch-board {
        width: 261px;
        height: 42px;
        border-radius: 8px;
        padding: 12px 0 12px 12px;
        background: $color-white;
        color: $color-gray;
        border: 1px solid $color-light-light-gray;
        /*border-right: none;*/
        .react-dropdown-select-content span {
          text-transform: capitalize;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 195px;
          overflow: hidden;
        }
        .dropdwonList-main {
          padding: 0;
          .dropdwonList-head {
            font-size: 15px;
            padding: 12px 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-gray;
            margin: 0;
            opacity: 0.5;
            padding-bottom: 12px;
            border-bottom: 1px solid $color-light-gray;
            cursor: default;
          }
          .dropdwonLists {
            overflow: auto;
            max-height: 200px;
            padding: 0;
            margin: 0;
            &::-webkit-scrollbar {
              width: 8px;
              height: 4px;
              background-color: $color-light-light-gray;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
              border-radius: 10px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
              background-color: $color-oldSilver;
            }
            .dropdwonLists-label {
              margin: 0;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-gray;
              display: flex;
              flex-direction: row;
              align-items: center;
              column-gap: 8px;
              padding: 12px;
              text-transform: capitalize;
              &.disabled,
              &:disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
              }
              &:hover {
                background-color: $color-light-ghost-white;
              }
              &.selected {
                background: $color-light-light-gray;
                color: $color-gray;
              }
            }
          }
          .archiveBoard-btn {
            font-size: 15px;
            padding: 12px 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-gray;
            margin: 0;
            border: none;
            background: transparent;
            width: 100%;
            border-top: 1px solid $color-light-gray;
            cursor: pointer;
            text-align: left;
            &:hover {
              background: $color-light-red-1;
            }
          }
        }
        .react-dropdown-select-dropdown-handle {
          border: none;
          width: 50px;
          height: 40px;
          border-radius: 8px 0px 0px 8px;
          background-color: $color-light-gray;
          margin: 0 !important;
          &:disabled {
            opacity: 0.5;
          }
          img,
          svg {
            vertical-align: -11px;
            width: 21px;
            height: 21px;
          }
        }
        .css-4ft19d {
          border-radius: 0px 8px 8px 0px;
        }
        & .react-dropdown-select-dropdown {
          margin: 0;
          border-radius: 6px;
          &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: rgb(167, 165, 165);
          }
          .react-dropdown-select-item {
            padding: 12.563px;
            background: $color-white;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            &:hover {
              background: $color-light-gray;
            }
            &:first-child {
              border-bottom: 1px solid $color-light-light-gray;
              background: none;
              color: $color-gray;
              cursor: default;
              ins {
                display: none;
              }
            }

            &:last-child {
              border-top: 1px solid $color-light-light-gray;
              background: $color-light-red-1;
              border-bottom: none;
            }
          }
          .react-dropdown-select-item-selected {
            background: $color-light-light-gray;
            color: $color-gray;
          }
        }
        & .react-dropdown-select-content {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          flex-wrap: nowrap;
          max-width: 350px;
          overflow: hidden;
          flex-wrap: nowrap;
          text-overflow: ellipsis;
          justify-content: flex-start;
          input {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-gray;
            &::placeholder {
              color: $color-gray;
            }
          }
        }
      }
    }
  }
}

.select-box__dropdown__card {
  width: 309px;
}

[class^="fs-"],
[class*=" fs-icon"] {
  font-family: "supportsystem" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.popupModal__container.delete-board .popupModal__root {
  width: 333px;
  height: 140px;
  padding: 16px;
  border-radius: 6px;
  .popupModal__heading h2 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    min-width: 100%;
    margin: 0;
    text-transform: capitalize;
    & .heading b {
      color: $color-primary;
    }
  }
  .icon-close {
    display: none;
  }
  .archive-message {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 12px 0 18px;
  }
  .flex-disp {
    display: flex;
    flex-direction: row;
    margin: 0px 0 0;
    align-items: center;
    column-gap: 20px;
    justify-content: flex-end;
    .board-delete {
      border-radius: 24px;
      background: $color-primary-dark;
      padding: 6px 16px;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-white;
      border: 1px solid transparent;
      max-width: 122px;
      height: 30px;
      &:hover {
        color: $color-white;
        background: #01101bd4;
        border: 1px solid $color-primary-dark;
      }
    }
    .board-cancel {
      border-radius: 24px;
      border: 1px solid $color-oldSilver;
      padding: 6px 16px;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: none;
      color: $color-oldSilver;
      width: 75px;
      height: 30px;
      &:hover {
        background: #dadcdd;
      }
    }
  }
}
.search-ticket {
  max-width: 304px;
  width: 50%;
  margin: 0 16px 6px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 42px;
  .search-input {
    width: 100%;
    padding: 13px 33px 13px 12px;
    border-radius: 8px;
    border: 1px solid $color-light-light-gray;
    background: $color-white;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-gray;
    &::placeholder {
      color: $color-oldSilver;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      opacity: 0.75;
      line-height: normal;
    }
  }
  .search-icon {
    position: absolute;
    right: 12px;
    top: 13px;
    cursor: pointer;
  }
}

.popupModal__container.create-ticket .popupModal__root {
  max-width: 735px;
  max-height: 700px;
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  .popupModal__heading {
    margin: 8px 0 20px;
    h2 {
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;
      min-width: 100%;
      margin: 0;
      text-transform: capitalize;
      & .heading b {
        color: $color-primary;
      }
    }
    .icon-close {
      color: $color-light-red;
      top: 43px;
      right: 24px;
    }
  }
  .popupModal__body {
    margin: auto;
    width: 100%;
    .pages-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 20px 0px 32px;
      button {
        background: transparent;
        border: 1px solid $color-light-light-gray;
        &.background {
          background: $color-primary;
          border: 1px solid $color-primary;
          & img {
            vertical-align: 1px;
          }
        }
        &.page-first,
        &.page-second {
          width: 32px;
          height: 32px;
          border-radius: 50px;
        }
        &.page-1,
        &.page-2,
        &.page-3 {
          width: 32px;
          height: 32px;
          border-radius: 50px;
        }
        &.border-text-color {
          color: $color-primary;
          border: 1px solid $color-primary;
        }
        &.page-connect {
          width: 94px;
          height: 1px;
          border: none;
          background: $color-primary;
        }
      }
    }
    .enquire-form-head {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: $color-primary-dark;
    }
    .company-data-content {
      width: 100%;
      margin: 20px 0 0 0;
      height: 100%;
      transition: height 10s ease-in-out;
      &::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: $color-light-light-gray;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
        border-radius: 10px;
        background-color: $color-light-light-gray;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
        background-color: $color-oldSilver;
      }
      .company-body {
        width: 100%;
        &::-webkit-scrollbar {
          width: 8px;
          height: 4px;
          background-color: $color-light-light-gray;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
          border-radius: 10px;
          background-color: $color-light-light-gray;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
          background-color: $color-oldSilver;
        }
        /*.rdtPicker {
          position: fixed;
          min-width: 250px;
          max-width: 322px;
        }*/
        .upcoming-releases-head {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          color: $color-darkLiver;
        }
      }
      .form-row {
        display: flex;
        flex-direction: row;
        column-gap: 25px;
        margin: 0 0 0px;
        flex-wrap: wrap;
        align-items: baseline;
        .popupModal__container.delete-agency-new-field .popupModal__root {
          width: 333px;
          height: 140px;
          padding: 16px;
          border-radius: 6px;
          .archive-message {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 12px 0 18px;
          }
          .flex-disp {
            display: flex;
            flex-direction: row;
            margin: 0px 0 0;
            align-items: center;
            column-gap: 20px;
            justify-content: flex-end;
            .board-cancel {
              border-radius: 24px;
              border: 1px solid $color-oldSilver;
              padding: 6px 16px;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              background: none;
              color: $color-oldSilver;
              width: 75px;
              height: 30px;
            }
            .board-delete {
              border-radius: 24px;
              background: $color-primary-dark;
              padding: 6px 16px;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-white;
              border: 1px solid transparent;
              max-width: 122px;
              height: 30px;
            }
          }
          .popupModal__heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $color-gray;
            background-color: transparent;
            justify-content: space-between;
            border-radius: 5px 5px 0px 0px;
            margin: 0;
            h2 {
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-align: left;
              min-width: 100%;
              margin: 0;
              text-transform: capitalize;
            }
            .icon-close {
              top: 13px;
              right: 24px;
            }
          }
        }
        .form-col {
          display: inline-flex;
          flex-direction: column;
          row-gap: 6px;
          position: relative;
          margin: 0 0 15px;
          width: 47%;
          .form-col-inline-flex {
            display: inline-flex;
            flex-direction: row;
            column-gap: 6px;
            position: relative;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            label {
              display: block;
            }
            .checkedIcon {
              width: 16px;
              height: 16px;
            }
          }
          label {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            color: $color-darkLiver;
            sup {
              vertical-align: sub;
              color: $color-light-red;
              font-size: 11px;
            }
            &.error-msg {
              color: $color-light-red;
              font-size: 11px;
              margin: 0px;
              padding: 0px;
              height: 8px;
            }
          }
          input {
            width: 320px;
            height: 42px;
            font-size: 14px;
            padding: 9px 30px 9px 15px;
            border-radius: 6px;
            border: 1px solid $color-light-light-gray;
            color: $color-oldSilver;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            &.search-label-input {
              border: none;
              font-size: 12px;
              height: inherit;
              padding: inherit;
              width: 100%;
            }
            &::placeholder {
              color: $color-light-gray-1;
              font-size: 12px;
            }
          }
          .suggestion-list {
            list-style: none;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 65px;
            left: 0;
            z-index: 1;
            width: 320px;
            background-color: $color-white;
            border: 1px solid $color-light-light-gray;
            border-radius: 4px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            color: var(--color-oldSilver);
            max-height: 200px;
            overflow: auto;
            overflow-x: hidden;
            li {
              padding: 8px;
              cursor: pointer;
              border-bottom: 1px solid $color-light-light-gray;
              &.company-not-found {
                color: $color-light-red;
              }
            }
            li:hover {
              background-color: #f0f0f0;
            }

            &::-webkit-scrollbar {
              width: 8px;
              height: 4px;
              background-color: $color-light-light-gray;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
              border-radius: 10px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
              background-color: $color-oldSilver;
            }
          }
          .search-icon {
            position: absolute;
            right: 12px;
            top: 32px;
          }
          .search-loading {
            position: absolute;
            right: 12px;
            top: 45px;
            border-radius: 100%;
            width: 0px;
            height: 0px;
            margin-top: -2px;
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-left-color: $color-gray;
            border-top-color: $color-gray;
            animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
          }
          .react-dropdown-select {
            width: 320px;
            border-radius: 6px;
            border: 1px solid $color-light-light-gray;
            background: $color-white !important;
            height: 42px;
            margin: 0;
            padding: 13px 15px 13px 12px;
            .react-dropdown-select-dropdown-handle svg {
              vertical-align: -4px;
              width: 16px;
              height: 16px;
              margin: 0;
            }
            &.departmentList {
              .react-dropdown-select-input {
                &::placeholder {
                  color: $color-light-red;
                }
              }
            }
            .dropdwonList-main {
              box-shadow: none;
              border-radius: 20px;
              .dropdwonLists {
                .dropdwonLists-label {
                  margin: 0 0 16px 0;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: $color-gray;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  column-gap: 8px;
                  label {
                    cursor: pointer;
                  }
                }
              }
            }
            .react-dropdown-select-content {
              color: $color-darkLiver;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: flex;
              flex-direction: row;
              max-width: 315px;
              overflow: hidden;
              flex-wrap: nowrap;
              text-overflow: ellipsis;
              justify-content: flex-start;
              & > span {
                white-space: nowrap;
              }
              input {
                width: 100%;
                height: inherit;
                padding: inherit;
                font-size: 12px;
                border: none;
              }
              .react-dropdown-select-option {
                background: transparent;
                color: $color-darkLiver;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                white-space: nowrap;
                margin: 0;
                &:first-child {
                  padding-left: 0px;
                }
              }
            }
            &.custom-dropdownRenderer {
              .react-dropdown-select-option {
                position: relative;
                display: inline;
                &::before {
                  position: absolute;
                  content: ",";
                  right: 0px;
                  bottom: 0;
                }
                &:nth-last-child(2)::before {
                  content: "";
                }
                .react-dropdown-select-option-remove {
                  display: none;
                }
              }
              .react-dropdown-select-dropdown {
                overflow: visible;
                border-radius: 6px;
                .dropdwonList-main {
                  padding: 12px;
                  .dropdwonList-head {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: $color-gray;
                    margin: 0 0 12px 0;
                    padding-bottom: 12px;
                    border-bottom: 1px solid $color-light-gray;
                  }
                  .dropdwonLists {
                    overflow: auto;
                    max-height: 130px;
                    padding: 0px 12px 12px 4px;
                    margin: 0 22px 0 10px;
                    .error-show {
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      margin: 0;
                      padding: 0;
                      color: $color-light-red;
                      text-align: center;
                    }
                    &.single {
                      margin: 0;
                      padding: 0;
                    }
                    &::-webkit-scrollbar {
                      width: 8px;
                      height: 4px;
                      background-color: $color-light-light-gray;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                      border-radius: 10px;
                      background-color: $color-light-light-gray;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 10px;
                      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                      background-color: $color-oldSilver;
                    }
                    .dropdwonList-subhead,
                    .dropdwonLists-label {
                      margin: 0 0 16px 0;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $color-gray;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      column-gap: 8px;
                      &.disabled,
                      &:disabled {
                        pointer-events: none;
                        cursor: not-allowed;
                        opacity: 0.5;
                      }
                      &:last-child {
                        margin: 0 0 0 0;
                      }
                      &.sub-list {
                        margin-left: 8px;
                      }
                      label {
                        cursor: pointer;
                      }
                      .checkbox-img {
                        width: 16px;
                        height: 100%;
                      }
                    }
                  }
                }
              }
              &.select-quatre {
                .react-dropdown-select-dropdown {
                  .dropdwonList-main {
                    .dropdwonLists {
                      padding: 0px 12px 12px 0px;
                      .dropdwonLists-label {
                        font-size: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
          .packageDropDwonList {
            border-radius: 6px;
            .react-dropdown-select-dropdown {
              border-radius: 6px;
              .dropdwonList-main {
                box-shadow: none;
                border-radius: 20px;
                .dropdwonLists {
                  padding: 12px 12px 0;
                  .dropdwonLists-label {
                    margin: 0 0 16px 0;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $color-gray;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 8px;
                    label {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
          .picker-date {
            position: relative;
            width: 320px;
            .form-control {
              width: 100%;
              background-color: transparent;
              border: 1px solid $color-light-light-gray;
              height: 42px;
            }
            .icon-ss-calendar {
              display: block;
              position: absolute;
              right: 10px;
              top: 8px;
              font-size: 23px;
              z-index: -1;
              &:before {
                color: $color-oldSilver;
              }
            }
            .rdtOpen .rdtPicker {
              bottom: inherit;
              top: 70px;
              position: fixed;
              width: 300px;
            }
          }
          &.upcoming-release {
            .picker-date {
              .rdtOpen {
                .rdtPicker {
                  top: inherit;
                  // bottom: 50px;
                }
              }
            }
          }
          .color-picker {
            background: #fff;
            box-shadow: -1px 1px 7px 1px rgba(27, 31, 53, 0.12);
            padding: 10px;
            position: absolute;
            right: 5px;
            top: 66px;
            z-index: 9999 !important;
            border-radius: 6px;
            border: 1px solid $color-light-light-gray-1;
            .color-picker__hex {
              input {
                width: 100%;
                padding: 2px;
              }
            }
            .color-picker__rbga-param {
              input {
                width: 100%;
                padding: 2px;
              }
            }
          }
          .color-sample {
            border: 1px solid $color-light-light-gray;
            cursor: pointer;
            height: 22px;
            position: absolute;
            right: 12px;
            top: 30px;
            width: 22px;
          }
          &.showToolTip {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-end;
            gap: 15px;
            width: 100%;
            .show-task-status {
              display: inline-flex;
              align-items: center;
              border-radius: 6px;
              justify-content: space-between;
              border: 1px solid $color-light-light-gray;
              background: $color-white;
              width: 100px;
              &.disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
              }
              .showToolTip {
                width: 100%;
                padding: 6px 12px;
                height: 35px;
                box-shadow: 0px 2px 4px 0px #1b1f351f;
                & .popup-content {
                  border-radius: 6px;
                  width: 100%;
                  & .showPopup .tip-entry {
                    font-size: 13px;
                    color: $color-darkLiver;
                    font-weight: 400px;
                    line-height: 15px;
                  }
                }
                .update-status {
                  font-weight: 500;
                  font-size: 13px;
                }
              }
            }
          }
        }
        &.custom-specifications {
          column-gap: 10px;
          .form-col {
            width: 30%;
            .yes-no-flex {
              display: inline-flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              column-gap: 14px;
              align-items: center;
              .checkbox-img {
                width: 14px;
                height: 14px;
                display: block;
                padding: 1px 0 0 0;
              }
              .yes-no-flex-value {
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                color: $color-darkLiver;
              }
            }
          }
        }

        .form-col-toolshow {
          width: 100%;
          .toolshow-head {
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: $color-gray;
          }
          .toollist-show {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            li {
              list-style-type: none;
              padding: 0;
              margin: 0;
              font-size: 13px;
              font-weight: 500;
              line-height: 15.73px;
              text-align: left;
              color: $color-gray;
              background-color: $color-silver;
              padding: 6px 8px;
              border-radius: 4px;
            }
          }
        }
        .form-col-final {
          display: inline-flex;
          flex-direction: column;
          row-gap: 6px;
          margin: 0 0 20px 0;
          label {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            color: $color-darkLiver;
            &.description {
              font-size: 15px;
            }
            sup {
              vertical-align: middle;
              color: $color-light-red;
            }
            &.error-msg {
              color: $color-light-red;
              font-size: 11px;
              margin: 0px;
              padding: 0px;
            }
          }
          textarea {
            width: 664px;
            height: 140px;
            border-radius: 6px;
            font-size: 13px;
            font-weight: 400;
            color: $color-darkLiver;
            border: 1px solid $color-light-light-gray;
            resize: none;
            padding: 6px 8px;
          }
          .defaultExist {
            min-width: 664px;
            min-height: 140px;
            border: 1px solid $color-light-light-gray;
            .ql-editor {
              min-height: 95px;
              &::placeholder {
                font-size: 11px;
              }
              p {
                color: $color-oldSilver;
              }
            }
          }
        }
        .no-need-to-update {
          display: inline-flex;
          flex-direction: column;
          row-gap: 6px;
          margin: 0 0 0px 0;
          width: 100%;
          align-items: end;
          justify-content: flex-end;
          label {
            font-size: 14px;
            font-weight: 400;
            line-height: 15px;
          }
          .checkedIcon {
            width: 30px;
            height: 25px;
          }
        }
        .form-buttons {
          display: flex;
          width: 100%;
          flex-direction: row;
          column-gap: 10px;
          justify-content: flex-end;
          margin: 10px 0 0;
          .cancel-form {
            width: 85px;
            height: 29px;
            border: 1px solid $color-oldSilver;
            color: $color-oldSilver;
            border-radius: 24px;
            background: $color-white;
            font-size: 14px;
          }
          .next-btn {
            width: 80px;
            height: 29px;
            padding: 2px 16px 2px;
            border-radius: 24px;
            background: $color-blue-4;
            color: $color-white;
            border: none;
            display: block;
            text-align: center;
            position: relative;
            font-size: 14px;
            &:disabled {
              opacity: 0.5;
            }
            &.loading {
              background: $color-primary-dark;
              padding-right: 40px;
              color: $color-white;
              width: 110px;
            }
            &.loading::before {
              content: "";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }
            @keyframes spin {
              to {
                transform: rotate(359deg);
              }
            }
            @keyframes grow {
              to {
                width: 14px;
                height: 14px;
                margin-top: -8px;
                right: 13px;
              }
            }
          }
          .create-btn {
            width: 81px;
            height: 29px;
            padding: 0px 16px 2px;
            border-radius: 24px;
            background: $color-blue-4;
            color: $color-white;
            border: none;
            display: block;
            text-align: center;
            position: relative;
            &.loading {
              background: $color-primary-dark;
              padding-right: 40px;
              color: $color-white;
              width: 105px;
            }
            &.loading::before {
              content: "";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }
            @keyframes spin {
              to {
                transform: rotate(359deg);
              }
            }
            @keyframes grow {
              to {
                width: 14px;
                height: 14px;
                margin-top: -8px;
                right: 13px;
              }
            }
          }
        }
        .input-field {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          row-gap: 6px;
          justify-content: flex-start;
          align-items: flex-start;
          min-width: 320px;
          margin: 0 0 15px 0;
          @media only screen and (max-width: 1280px) {
            & {
              min-width: 300px;
            }
          }
          .fs-opf-eye-dropper:before {
            content: "\e925";
            color: #014a87;
          }

          position: relative;
          .flex-col {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            width: 100%;
            line-height: 100%;
            padding: 0;
            .head-field {
              display: inline-flex;
              flex-direction: row;
              flex-wrap: nowrap;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $color-darkLiver;
              border: none;
              width: 70%;
              sup {
                color: $color-light-red;
                top: 2px;
              }
            }
            .remove-field {
              background-color: $color-white;
              border: none;
              padding: 0;
              margin: 0;
              line-height: 100%;
            }
          }

          .flex-col2 {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: baseline;
            margin: 0;
            line-height: 100%;
            padding: 0;
            width: 100%;
            .add-field {
              background-color: $color-white;
              border: none;
              color: $color-oldSilver;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 0;
              line-height: 100%;
              min-width: 110px;
              height: 15px;
              &:disabled {
                opacity: 0.5;
              }
            }
            .error-msg {
              color: $color-light-red;
              font-size: 11px;
              margin: 0px;
              padding: 0px;
            }
          }
          .label,
          label,
          .input-type {
            width: 100%;
            &.package {
              user-select: none;
              pointer-events: none;
            }
          }
          .search-icon {
            position: absolute;
            right: 12px;
            top: 35px;
          }
          label {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-darkLiver;
            border: none;
            &.disabelEdit {
              pointer-events: none;
            }
            &.clicked {
              border: 1px solid $color-light-light-gray;
            }
            sup {
              color: $color-light-red;
              vertical-align: middle;
            }
          }
          .label {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-darkLiver;
            border: none;
            &.disabelEdit {
              pointer-events: none;
            }
            &.clicked {
              border: 1px solid $color-light-light-gray;
            }
            sup {
              color: $color-light-red;
              vertical-align: middle;
            }
          }
          .input-type {
            font-size: 14px;
            padding: 9px 30px 9px 15px;
            border-radius: 6px;
            border: 1px solid $color-light-light-gray;
            color: $color-oldSilver;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &::placeholder {
              color: $color-light-gray-1;
              font-size: 12px;
            }
          }

          .suggestion-list {
            list-style: none;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            width: 100%;
            background-color: $color-white;
            border: 1px solid $color-light-light-gray;
            border-radius: 4px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            color: var(--color-oldSilver);
            max-height: 200px;
            overflow: auto;
            overflow-x: hidden;
            li {
              padding: 8px;
              cursor: pointer;
              border-bottom: 1px solid $color-light-light-gray;
              &.company-not-found {
                color: $color-light-red;
              }
            }
            li:hover {
              background-color: #f0f0f0;
            }

            &::-webkit-scrollbar {
              width: 8px;
              height: 4px;
              background-color: $color-light-light-gray;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
              border-radius: 10px;
              background-color: $color-light-light-gray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
              background-color: $color-oldSilver;
            }
          }
          .color-sample {
            width: 22px;
            height: 22px;
            position: absolute;
            right: 12px;
            top: 35px;
            cursor: pointer;
            border: 1px solid $color-light-light-gray;
          }
          .color-picker {
            position: absolute;
            top: 66px;
            background: #fff;
            box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
            z-index: 99;
            padding: 10px;
            right: 0;
          }
        }
        .marketList-dropdownRenderer {
          .customDropDown {
            max-height: 300px;
            .customDropDown_dropdwon_list {
              max-height: 150px;
            }
          }
        }
      }
      .tabs-content {
        .tabs-list {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          column-gap: 20px;
          margin: 0 0 20px;
          overflow: auto;
          max-width: 700px;
          white-space: nowrap;
          padding: 0 10px 0px 0;
          .tabs-list-btn {
            font-size: 18px;
            font-weight: 400;
            line-height: 21.6px;
            text-align: left;
            border: none;
            background: none;
            color: $color-light-gray-1;
            border-bottom: 2px solid transparent;
            padding: 0 0 2px;
            &.active {
              font-weight: 600;
              border-bottom: 2px solid $color-primary;
              color: $color-dark-gray-1;
            }
          }
        }
        .tabs-body {
          .filter-col1 {
            width: 65%;
            margin: 0 0 10px;
            input {
              width: 100%;
              height: 42px;
              font-size: 14px;
              padding: 9px 30px 9px 15px;
              border-radius: 6px;
              border: 1px solid $color-light-light-gray;
              color: $color-oldSilver;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
            }
          }
          .filter-col2 {
            width: 30%;
            margin: 0 0 10px;
            .filter-search-button {
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              column-gap: 8px;
              .react-dropdown-select {
                width: 150px;
                border-radius: 6px;
                border: 1px solid $color-light-light-gray;
                background: $color-light-gray !important;
                height: 42px;
                margin: 0;
                padding: 13px 15px 13px 12px;
                .react-dropdown-select-content {
                  font-weight: 600;
                }
              }
              .search-button {
                background: $color-primary-dark;
                border: none;
                border-radius: 8px;
                width: 42px;
                height: 42px;
                .search-icon {
                  position: absolute;
                  right: 11px;
                  top: 47px;
                  filter: invert(100%) brightness(1000%) contrast(100%);
                }
              }
            }
          }
          .heading {
            font-size: 15px;
            font-weight: 400;
            line-height: 19px;
            text-align: left;
            color: $color-darkLiver;
            margin: 0 0 10px;
          }
          .form-row .form-col-final {
            label {
              font-size: 15px;
              font-weight: 400;
              line-height: 19px;
              text-align: left;
              color: $color-darkLiver;
              margin: 10px 0 0px;
            }
            .defaultExist {
              width: 100%;
              min-height: 80px;
            }
          }
          .search-to-add-peers-indices-list {
            width: 100%;
            height: 100px;
            padding: 12px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: 1px solid $color-light-light-gray;
            margin: 0 0 20px;
            .heading {
              font-size: 13px;
              font-weight: 400;
              line-height: 15.73px;
              text-align: left;
              color: $color-silver;
              text-align: center;
              margin: auto;
            }
          }
        }
      }
      .attachments-content {
        margin: 0px 0px 20px;
        .heading {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          display: flex;
          align-items: center;
          color: $color-darkLiver;
          justify-content: space-between;
          margin: 10px 0;
          border: none;
          padding: 0;
          border-radius: 0;
          .icon-upload_file {
            font-size: 21px;
            font-weight: 500;
            line-height: 15.73px;
            text-align: left;
            background: none;
            border: none;
            padding: 0;
          }
        }
        .ticket_info_container_updatedDetails {
          display: block;
          border: 1px solid $color-platinum;
          border-radius: 5px;
          margin: 0 0;
          position: relative;
          font-size: 14px;
          color: $color-darkLiver;
          padding: 10px;
        }
      }
      .tools-head {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0px 0px 10px;
        color: $color-primary-dark;
      }
      .source-details-heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 24.8px;
        margin: 0px 0px 10px;
      }
      .warning-msg {
        color: $color-light-red;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        margin: 25px 0;
        text-align: center;
      }
      .ticker-tabs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;
        margin: 0 0 20px;
        overflow: auto;
        max-width: 700px;
        white-space: nowrap;
        padding: 0 10px 0px 0;
        border-bottom: 1px solid $color-light-light-gray;
        &::-webkit-scrollbar {
          width: 8px;
          height: 4px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: rgb(167, 165, 165);
        }
        .ticker-tabs-btns {
          font-size: 18px;
          font-weight: 400;
          line-height: 21.6px;
          text-align: left;
          border: none;
          background: none;
          color: $color-light-gray-1;
          padding: 0 0 2px;
          &.active {
            font-weight: 600;
            border-bottom: 2px solid $color-primary-dark;
            color: $color-primary-dark;
          }
        }
      }
    }
  }
}
.popupModal__container.duplicate-confirm {
  .popupModal__root {
    width: 360px;
    border-radius: 6px;
    padding: 16px;
    .popupModal__heading {
      margin: 8px 0 20px;
      h2 {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        min-width: 100%;
        margin: 0;
        color: $color-primary-dark;
        & .heading b {
          color: $color-primary;
        }
      }
      .icon-close {
        font-size: 12px;
        top: 28px !important;
      }
    }
    .messageContent {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 12px 0 18px;
      color: $color-darkLiver;
    }
    .flex-disp {
      display: flex;
      flex-direction: row;
      margin: 0px 0 0;
      align-items: center;
      column-gap: 20px;
      justify-content: flex-end;
      .confirmBtn {
        border-radius: 24px;
        background: $color-primary-dark;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-white;
        border: 1px solid transparent;
        max-width: 122px;
        height: 30px;
        &:hover {
          color: $color-white;
          background: #01101bd4;
          border: 1px solid $color-primary-dark;
        }
      }
      .cancelBtn {
        border-radius: 24px;
        border: 1px solid $color-oldSilver;
        padding: 6px 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: none;
        color: $color-oldSilver;
        width: 75px;
        height: 30px;
        &:hover {
          background: #dadcdd;
        }
      }
    }
  }
}

.popupModal__container.confirm-ticket .popupModal__root {
  max-width: 85%;
  max-height: 85%;
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  @media (max-width: 1366px) {
    overflow-y: scroll;
  }
  .confirm-ticketbody {
    width: 100%;
    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    h2 {
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;
      margin: 0;
      text-transform: capitalize;
      color: $color-blue-4;
      & .heading b {
        color: $color-blue-4;
      }
    }
    &__update-text {
      text-decoration: underline;
      font-weight: 500;
      color: $color-blue-4;
    }
    &__confirm-text {
      color: #828282;
      margin-top: 5px;
      font-size: 15px;
    }
    &__preview-box {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      border: 1px solid $color-light-light-gray-1;
      padding: 1%;
      border-radius: 5px;
    }
    &__tab-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 15px;
    }

    .triangle-box {
      position: relative;
      background: $color-light-ghost-white; /* Button color */
      border: 1px solid #082b45;
      text-align: center;
      padding: 6px 20px; /* Adjusted padding for better appearance */
      border-radius: 5px; /* Optional: rounded corners for smoother look */
      display: inline-block; /* Ensures proper box sizing */
      cursor: pointer; /* Make it clickable */
      z-index: 1; /* Keep the box above the triangle */
      font-weight: 600;
    }

    .triangle-box1 {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -10px; /* Places triangle just below the button */
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #082b45; /* Same color as the button */
      z-index: 0; /* Behind the button */
    }

    &__footer_row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1%;
      .cancel-form {
        width: 85px;
        height: 29px;
        border: 1px solid $color-oldSilver;
        color: $color-oldSilver;
        border-radius: 24px;
        background: $color-white;
        font-size: 14px;
      }
      .next-btn {
        width: 80px;
        height: 29px;
        padding: 0px 16px 2px;
        border-radius: 24px;
        background: $color-blue-4;
        color: $color-white;
        border: none;
        display: block;
        text-align: center;
        position: relative;
        font-size: 14px;
        &:disabled {
          opacity: 0.5;
        }
        &.loading {
          background: $color-primary-dark;
          padding-right: 40px;
          color: $color-white;
          width: 110px;
        }
        &.loading::before {
          content: "";
          position: absolute;
          border-radius: 100%;
          right: 6px;
          top: 50%;
          width: 0px;
          height: 0px;
          margin-top: -2px;
          border: 2px solid rgba(255, 255, 255, 0.5);
          border-left-color: #fff;
          border-top-color: #fff;
          animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
        }
        @keyframes spin {
          to {
            transform: rotate(359deg);
          }
        }
        @keyframes grow {
          to {
            width: 14px;
            height: 14px;
            margin-top: -8px;
            right: 13px;
          }
        }
      }
      .create-btn {
        width: 81px;
        height: 29px;
        padding: 0px 16px 2px;
        border-radius: 24px;
        background: $color-blue-4;
        color: $color-white;
        border: none;
        display: block;
        text-align: center;
        position: relative;
        &.loading {
          background: $color-primary-dark;
          padding-right: 40px;
          color: $color-white;
          width: 105px;
        }
        &.loading::before {
          content: "";
          position: absolute;
          border-radius: 100%;
          right: 6px;
          top: 50%;
          width: 0px;
          height: 0px;
          margin-top: -2px;
          border: 2px solid rgba(255, 255, 255, 0.5);
          border-left-color: #fff;
          border-top-color: #fff;
          animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
        }
        @keyframes spin {
          to {
            transform: rotate(359deg);
          }
        }
        @keyframes grow {
          to {
            width: 14px;
            height: 14px;
            margin-top: -8px;
            right: 13px;
          }
        }
      }
    }
    &__render-box {
      width: 100%;
      margin: 20px 0 0 0;
      &::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(167, 165, 165);
      }
      border-radius: 10px;
      .general-info {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 10px 12px 13px;
        gap: 16px;
        background-color: $color-primary-light-3;
        display: flex;
        flex-wrap: wrap;
        gap: 16px; /* White space between columns */
      }
      .Design-info {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 10px 12px 13px;
        gap: 10px;
        background-color: $color-primary-light-3;
        .column-row {
          width: 22%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .variable_name {
            color: $color-light-gray-11;
            padding: 0;
            margin: 0;
          }
          .name {
            color: $color-blue-4;
          }
          .value_text {
            color: $color-primary-dark;
            font-weight: 600;
            font-size: 16px;
            padding: 0;
            margin: 2px 0 0 0;
            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .secondary_market_box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .array_map_box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
          }
        }
      }
      .column-row {
        display: flex;
        flex: 0 1 calc(20% - 16px); /* 5 columns */
        min-width: 180px;
        padding: 10px;
        border-radius: 8px;
        flex-direction: column;
        align-items: flex-start;
        .variable_name {
          color: $color-light-gray-11;
          padding: 0;
          margin: 0 0 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.4px;
        }
        .name {
          color: $color-blue-4;
        }
        .value_text {
          color: $color-primary-dark;
          font-weight: 600;
          font-size: 16px;
          padding: 0;
          margin: 0;
        }
        a.value_text {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-wrap: nowrap;
        }
        .variable_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          column-gap: 8px;
        }
        &.tool-List {
          width: 100%;
          margin: 25px 0 0 0;
          display: inline-block;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .variable_name {
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            color: $color-gray;
            margin: 0 0 17px;
          }
          .variable_list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 8px;
            column-gap: 8px;
          }
          .value_text {
            color: $color-gray;
            font-weight: 500;
            font-size: 13px;
            padding: 6px 8px;
            margin: 0;
            background-color: $color-silver;
            border-radius: 4px;
          }
        }
      }

      .custom-specification {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        &__header_row {
          background-color: $color-blue-4;
          width: 100%;
          display: flex;
          flex-direction: row;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &__variable_column {
          width: 75%;
          padding: 1%;
          display: flex;
          align-items: center;
          flex-direction: row;
          p {
            font-weight: 500;
          }
        }
        &__value_column {
          width: 25%;
          padding: 1%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          p {
            font-weight: 500;
          }
        }
        p {
          padding: 0;
          margin: 0;
          color: $color-dark-gray-6;
          &.not-applicable {
            color: $color-dark-gray-6;
          }
          &.required {
            color: $color-dark-green-1;
          }
          &.not-required {
            color: $color-light-red;
          }
        }
      }

      /*************************************************/
      @media (max-width: 1200px) {
        .column-row {
          flex: 0 1 calc(25% - 16px); /* 4 columns for medium screens */
        }
      }

      @media (max-width: 768px) {
        .column-row {
          flex: 0 1 calc(33.33% - 16px); /* 3 columns for smaller screens */
        }
      }

      @media (max-width: 480px) {
        .column-row {
          flex: 0 1 calc(50% - 16px); /* 2 columns for very small screens */
        }
      }

      @media (max-width: 360px) {
        .column-row {
          flex: 0 1 100%; /* 1 column for very small screens */
        }
      }

      /***************************************************/
    }
  }
}
