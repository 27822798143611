.company {
  padding: 0 25px;
  &__header {
    padding: 31px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1366px) {
      padding: 20px 0 20px;
    }
    h4 {
      margin: 0;
    }
    &-right {
      display: inline-flex;
      justify-content: flex-end;
      gap: 15px;

      .search-ticket {
        width: 304px;
        margin: 0;
        .search-input {
          width: 100%;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid var(--color-light-light-gray);
          background: var(--color-white);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: var(--color-gray);
          margin: 0;
        }
      }
      .add-company-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        background: $color-primary-dark;
        color: $color-white;
        min-width: 146px;
        min-height: 42px;
        border-radius: 26px;
        padding: 12px 16px;
        border: none;
      }
    }
  }
  &__filter {
    display: flex;
    justify-content: space-between;
    &-left {
      display: inline-flex;
      gap: 14px;
      flex-wrap: wrap;
      .search-input {
        width: 304px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--color-light-light-gray);
        background: var(--color-white);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--color-gray);
      }
    }
    &-right {
      height: 42px;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      color: var(--color-primary-180, rgba(8, 43, 69, 0.8));
      display: flex;
      gap: 8px;
      button {
        border-radius: 28px;
        width: 120px;
        padding: 10px;
        background: $color-white;
        border: 1px solid $color-light-light-gray;
        color: $color-oldSilver;
      }
      button[type="submit"] {
        background: $color-light-light-gray;
        color: $color-primary-dark;
      }
      button[type="submit"].active {
        background: $color-primary-dark;
        color: $color-white;
      }
    }
    // &-right {
    //   display: inline-flex;
    //   gap: 14px;
    //   button {
    //     border: unset;
    //     border-radius: 30px;
    //     min-width: 62px;
    //     height: 39px;
    //     color: $color-oldSilver;
    //     text-align: center;
    //     font-size: 13px;
    //     font-weight: 500;
    //     padding: 12px 14px;
    //     align-items: center;
    //     justify-content: center;
    //     text-align: center;
    //     font-size: 13px;
    //     font-style: normal;
    //     font-weight: 500;
    //     line-height: 100%;
    //     column-gap: 10px;
    //     display: flex;
    //   }
    //   .active {
    //     background: $color-gray;
    //     color: $color-white;
    //   }
    // }
  }
  &__packages {
    display: flex;
    justify-content: right;
    padding-top: 25px;
    &-right {
      display: flex;
      gap: 12px;
      button {
        border: unset;
        // padding: 12px 14px;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        column-gap: 10px;
        background: transparent;
        display: inline-flex;
        gap: 6px;
        align-items: center;
        cursor: default;
        & span {
          font-size: 14px;
        }
      }
    }
  }
  &__listing {
    display: flex;
    gap: 16px;
    padding-top: 14px;
    padding-bottom: 20px;
    flex-flow: wrap;
    // justify-content: space-between;
    &__container {
      display: flex;
      // width: 316px;
      width: 24%;
      padding: 2px 2px 18px 12px;
      align-items: flex-start;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid $color-light-gray;
      flex-direction: column;
      cursor: pointer;
      user-select: none;
      box-shadow: 0px 2px 6px 0px #5555;
      &-info {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        .logo {
          display: block;
          fill: $color-white;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 48px;
          height: 54px;
          margin-top: 16px;
          span {
            display: inline-flex;
            width: 54px;
            height: 54px;
            img {
              width: 100%;
              object-fit: contain;
            }
          }
          .no-img {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: white;
            line-height: 30px;
          }
        }
        .package--indicator {
          width: 28px;
          height: 28px;
          .ir-premium {
            background: rgba(255, 186, 87, 1);
          }
          .ir-basic {
            background: rgba(250, 123, 123, 1);
          }
          .ir-medium {
            background: rgba(81, 175, 228, 1);
          }
          .ir-plus {
            background: rgba(165, 216, 67, 1);
          }
          .ir-tools-only {
            background: rgba(165, 216, 67, 1);
          }
          span {
            width: 100%;
            height: 100%;
            display: flex;
            border-top-right-radius: 6px;
            border-bottom-left-radius: 6px;
            * {
              font-size: 16px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              &::before {
                color: white;
              }
            }
          }
        }
      }
      &-package {
        color: $color-gray;
        .company-name,
        a {
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        .location {
          color: $color-black;
          margin-bottom: 8px;
        }
        p {
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          span {
            color: $color-black;
          }
        }
      }
    }
    .no-record {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 100px;
    }
  }
}
.customers-body-content {
  padding: 24px 23px;
  .customers-data-flex {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 24px 0;
    &__col1 {
      width: 120px;
      height: 120px;
      display: block;
      .logo-img {
        display: inline-flex;
        width: 120px;
        fill: var(--color-white);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 80px;
        height: 120px;
        align-items: center;
        img {
          width: 100%;
          object-fit: contain;
          padding: 4px;
        }
        .customerName {
          display: inline-block;
          text-align: center;
          width: 100%;
          border-radius: 80px;
          font-size: 35px;
          color: $color-white;
        }
      }
    }
    &__col2 {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      &--innerflex--col1 {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        .customers--heading {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $color-gray;
        }
        button {
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          border-radius: 32px;
          background: $color-primary-dark;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 8px 16px;
          color: $color-white;
          border: none;
        }
      }
      &--innerflex--col2 {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 0px 0px 5px 5px;
        border-top: 1px solid $color-silver;
        background: $color-light-ghost-white;
        margin: 12px 0 0;
        padding: 24px 15px 10px;
        p {
          margin: 0;
        }
        .latest-order {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-primary-dark;
          display: table;
          .td-1 {
            width: 150px;
            display: table-cell;
          }
          .td-2 {
            display: table-cell;
            width: 15px;
          }
          .td-3 {
            font-weight: 500;
          }
        }
        .created {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 12px 0 0;
          display: table;
          color: $color-gray;
          .td-1 {
            display: table-cell;
            width: 80px;
          }
          .td-2 {
            display: table-cell;
            width: 15px;
          }
          .td-3 {
            display: table-cell;
            font-weight: 500;
          }
        }
        .inner-flex {
          display: inline-flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          p {
            margin: 0;
            .td-1 {
              display: table-cell;
              width: 80px;
            }
            .td-2 {
              display: table-cell;
              width: 15px;
            }
          }
          .inline-flex {
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;
            width: 40%;
            align-items: flex-end;
            column-gap: 30px;
            .status {
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 12px 0 0;
              display: table;
              color: $color-gray;
              .td-3 {
                display: table-cell;
                font-weight: 500;
                position: relative;
                color: $color-darkLiver;
                span {
                  padding-left: 8px;
                  &:first-child {
                    padding-left: 0px;
                  }
                  &::before {
                    content: ",";
                    position: absolute;
                    bottom: 0;
                  }
                  &:last-child::before {
                    content: "";
                  }
                }
              }
              .deliverd-count {
                b {
                  color: $color-dark-green;
                }
              }
              .not-Started {
                b {
                  color: $color-light-red;
                }
              }
            }
            .priority {
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 12px 0 0;
              display: table;
              color: $color-gray;
              .td-3 {
                display: table-cell;
                font-weight: 500;
                color: $color-orange;
              }
            }
          }
          button {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            border: none;
            background: transparent;
            color: $color-primary-dark;
          }
        }
      }
    }
  }
}

.customers-info {
  display: block;
  .customers-data-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 32px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    border-bottom: 1px solid $color-light-light-gray;
    padding: 0 0 0 23px;
    button {
      background-color: $color-white;
      border: none;
      padding: 10px;
      color: $color-darkLiver;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-bottom: 2px solid transparent;
      &.active {
        font-weight: 500;
        line-height: normal;
        color: $color-gray;
        border-bottom: 2px solid $color-gray;
      }
    }
  }
  .customers-overview {
    padding: 24px 23px;
    .subscription-dtl {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: $color-gray;
      margin: 0 0 8px;
    }
    .subscription-prd {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-gray;
      margin: 0 0 8px;
      button {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0 0 0 10px;
        color: $color-primary;
      }
    }
    .services-page {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: $color-gray;
      margin: 0 0 24px;
    }
    .collapseCard {
      border-bottom: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 12px 0px 6px;
      &.customer-timeline {
        border-top: none;
      }
      &:last-child {
        border-bottom: 1px solid $color-platinum;
      }
      &__heading {
        padding: 9px 40px;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: $color-gray;
        .icon-chevron-thin-down {
          display: inline-block;
          position: absolute;
          left: 12px;
          &::before {
            font-size: 12px;
            vertical-align: -2px;
            font-weight: 700;
          }
        }
        &.active {
          background-color: transparent;
          color: initial;
          .icon-chevron-thin-down {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            &::before {
              vertical-align: 4px;
            }
          }
        }
      }
      .collapseCard__body {
        background: transparent;
        display: none;
        &.show {
          display: block;
          background: $color-light-ghost-white;
          border-top: 1px solid $color-platinum;
          border-radius: 0px 0px 5px 5px;
          padding: 24px 20px 24px 40px;
          margin: 0 0 6px;
          .customer-details-contet {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 0 12px 0;
            .customer-details-contet--col1,
            .customer-details-contet--col2 {
              margin: 0;
              padding: 0;
              width: 50%;
              .customer-details-table {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
                width: 100%;
                .customer-field {
                  width: 40%;
                  max-width: 300px;
                  padding: 9px;
                  vertical-align: top;
                  color: $color-darkLiver;
                }
                .custom-field-dividend {
                  padding: 9px 4px;
                  text-align: center;
                  width: 20px;
                  vertical-align: top;
                }
                .customer-field-value {
                  font-weight: 500;
                  padding: 9px;
                  vertical-align: top;
                  color: $color-gray;
                }
              }
            }
          }
        }
      }
    }
  }
  .customers-contacts {
    padding: 25px 20px;
    .contact-no-found {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-gray;
    }
    .customers-contacts-info {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-gray;
      margin: 0 0 24px;
    }
    .name-cards {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      flex-wrap: wrap;
      .name-card {
        border-radius: 6px;
        background: $color-light-ghost-white;
        padding: 24px 25px;
        width: 424px;
        min-height: 199px;
        margin: 0px 0 18px;
        p {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;
          margin: 0 0 12px;
          color: $color-gray;
          &:last-child {
            margin: 0;
          }
        }
        .name-field {
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          align-items: center;
          color: $color-primary-dark;
          column-gap: 12px;
          padding: 0px 0px 12px;
          border-bottom: 1px solid $color-light-light-gray;
          margin: 0 0 15px;
          .name-firstletter {
            display: block;
            width: 34px;
            height: 34px;
            color: $color-white;
            background-color: $color-dark-green;
            padding: 2px 4px;
            line-height: 30px;
            text-align: center;
            border-radius: 50px;
          }
          .fullname {
            color: $color-primary-dark;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
  .customers-servicesLinks {
    padding: 24px 23px;
    .collapseCard {
      border-bottom: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 12px 0px 6px;
      &.customer-premium-package {
        p {
          color: $color-darkLiver;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &.customer-ordered-tools {
        border-top: none;
      }
      &:last-child {
        border-bottom: 1px solid $color-platinum;
      }
      &__heading {
        padding: 9px 40px;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: $color-gray;
        .icon-chevron-thin-down {
          display: inline-block;
          position: absolute;
          left: 12px;
          &::before {
            font-size: 12px;
            vertical-align: -2px;
            font-weight: 700;
          }
        }
        &.active {
          background-color: transparent;
          color: initial;
          .icon-chevron-thin-down {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            &::before {
              vertical-align: 4px;
            }
          }
        }
      }
      .collapseCard__body {
        background: transparent;
        display: none;
        &.show {
          display: block;
          background: $color-light-ghost-white;
          border-top: 1px solid $color-platinum;
          border-radius: 0px 0px 5px 5px;
          padding: 12px;
          margin: 0 0 6px;
        }
        .comman-table {
          width: 100%;
          .table-container {
            margin: 0;
            padding: 0;
            border: none;
            overflow: hidden;
            overflow-x: auto;
            max-width: calc(100vw - 230px);
            &::-webkit-scrollbar {
              width: 6px;
              height: 8px;
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: rgb(167, 165, 165);
            }
            table {
              width: 100%;
              thead th {
                padding: 0 8px 24px;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $color-gray;
                &.package-owned {
                  text-align: center;
                }
                &.tool-center {
                  text-align: center;
                }
              }
              tbody td {
                padding: 0 8px 15px;
                color: $color-darkLiver;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                & .package-owned {
                  text-align: center;
                }
                & .tool-center {
                  text-align: center;
                }
                & .tool-linkname {
                  color: $color-gray;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .customers-ordersInvoices {
    padding: 24px 23px;
    .ordersInvoices-no-found {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-gray;
    }
    .ordersInvoices-table {
      width: 100%;
      margin: 24px 0;
      tr {
        td {
          padding: 12px 12px 6px;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-oldSilver;
          &.collaps-icon {
            width: 24px;
            cursor: pointer;
            .icon-chevron-thin-down:before {
              color: $color-primary-dark;
              font-weight: 600;
            }
            .icon-chevron-thin-down.showDataIcon {
              display: block;
              transform: rotate(180deg);
            }
          }
          &.invoice-header {
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-darkLiver;
            width: 150px;
          }
          .search-message {
            text-align: center;
            color: $color-light-red;
          }
          &.priority-header {
            span {
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            .high {
              background-color: $color-light-red;
              color: $color-white;
              padding: 3px 6px;
              min-width: 41px;
              border-radius: 50px;
            }
            .normal,
            .medium {
              background-color: $color-orange;
              color: $color-white;
              padding: 3px 6px;
              min-width: 50px;
              border-radius: 50px;
            }
          }
        }
        &.bolded-td td {
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $color-darkLiver;
          &:nth-child(2) {
            font-size: 13px;
          }
          &.action-value {
            color: $color-primary;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        &:nth-child(even) td {
          border-bottom: 1px solid $color-light-light-gray;
          padding: 6px 12px 14px;
        }
        &.border-none td {
          border-bottom: none;
        }
        &.tools-owned {
          background: $color-light-ghost-white;
          box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
          &.first-child td:first-child {
            border-radius: 8px 0px 0px 0px;
          }
          &.first-child td:last-child {
            border-radius: 0px 8px 0px 0px;
          }
          &.border-none td:first-child {
            border-radius: 0px 0px 0px 8px;
          }
          &.border-none td:last-child {
            border-radius: 0px 0px 8px 0px;
          }
          td {
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-darkLiver;
            &.tool-name {
              font-size: 17px;
            }
            &.tool-status {
              font-size: 15px;
            }
            &.createdby {
              font-size: 11px;
            }
          }
          &:nth-child(odd) td {
            padding: 17px 12px 6px;
            &:first-child {
              padding-left: 19px;
            }
          }
          &:nth-child(even) td {
            padding: 4px 12px 15px;
            &:first-child {
              padding-left: 19px;
            }
          }
          &:first-child {
            border-bottom: none;
          }
        }
      }
    }
    .filter-options {
      display: flex;
      flex-direction: row;
    }
  }
}
.data-heading {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $color-gray;
}
.assignTools-popup {
  .popupModal__root {
    padding: 30px 24px 0px;
    border-radius: 6px;
    .popupModal__heading {
      h2 {
        text-align: left;
        margin: 0 0 15px;
        color: $color-primary-dark;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .icon-close {
        right: 24px;
        top: 30px;
      }
    }
    .popupModal__body {
      max-width: 900px;
      .selectDate {
        margin: 0 0 24px;
        width: 304px;
        display: flex;
        flex-direction: row;
        position: relative;
        &-input {
          border-radius: 8px;
          border: 1px solid $color-light-light-gray;
          background: #f9f9f9;
          width: 304px;
          height: 42px;
          padding: 9px 18px 9px 12px;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-oldSilver;
        }
        &-icon {
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          right: 18px;
          top: 8px;
          color: $color-oldSilver;
        }
      }
      .assign-flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 24px;
        &-box {
          max-width: 422px;
          min-width: 422px;
          padding: 24px;
          border-radius: 6px;
          border: 1px solid $color-light-gray;
          background: $color-white;
          margin: 0 0 24px 0;
          .toolsName {
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-darkLiver;
            margin: 0 0 18px;
            width: 100%;
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;
            .tool-name {
              display: block;
              white-space: nowrap;
              width: 270px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &--assigned {
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              border-radius: 40px;
              padding: 4px 7px;
              height: 24px;
              background: $color-dark-green;
              border: none;
              color: $color-white;
            }
            &--unassigned {
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              border-radius: 40px;
              padding: 4px 7px;
              background: $color-light-red;
              border: none;
              height: 24px;
              color: $color-white;
            }
          }
          .startedby {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-darkLiver;
            margin: 0 0 15px;
          }
          .assigned-name {
            border-radius: 8px;
            border: 1px solid $color-light-light-gray;
            background: $color-light-ghost-white;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 13px 12px;
            margin: 0;
            min-width: 304px;
            height: 42px;
            color: $color-oldSilver;
          }
        }
      }
    }
  }
}
