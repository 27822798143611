.ticket_info_right-panel {
  padding: 30px 20px;
  background: #f9f9f9;
  border-left: 1px solid $color-light-light-gray;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: -2px 3px 8px 1px $color-light-light-gray;
  z-index: 100;
  @media (max-width: 1100px) {
    &-expand__arrow {
      position: fixed;
      bottom: calc(50% - 57px);
      right: 0px;
      border-radius: 24px 0px 0px 24px;
      border-top: 1px solid $color-light-light-gray;
      border-left: 1px solid $color-light-light-gray;
      border-bottom: 1px solid $color-light-light-gray;
      background: $color-white;
      box-shadow: 0px 0px 6px 0px rgba(27, 31, 53, 0.05);
      padding: 8px 5px;
      cursor: pointer;
      z-index: 110;
      img {
        margin: 0;
        padding: 0;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 9px;
    height: 8px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(167, 165, 165);
  }
  &.disableClass {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .data_section {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-light-light-gray;
    padding-bottom: 16px;
    position: relative;
    &:not(:first-child) {
      padding-top: 16px;
    }
    &_list {
      width: 100%;
      &_image {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 0;
        width: 42px;
        height: 42px;
        overflow: hidden;
        border-radius: 50%;
        fill: var(--color-white);
        border: 1px solid $color-primary;
        /*box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);*/
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          word-break: break-all;
        }
        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 19px;
          line-height: 100%;
          text-align: center;
          border-radius: 50%;
          color: $color-white;
        }
        .avatars__others {
          background-color: #d9d9d9;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          font-size: 13px;
          font-weight: 500;
          line-height: 100%;
          color: var(--color-darkLiver);
          border-radius: 50%;
        }
      }
      &_data {
        padding-top: 12px;
        font-size: 17px;
        text-transform: capitalize;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        &.orderID {
          word-break: break-all;
        }
        .picker-date {
          width: 306px;
          position: relative;
          .icon-ss-calendar {
            display: block;
            position: absolute;
            right: 10px;
            top: 6px;
            background: $color-light-gray-bg;
            border-radius: 25px;
            padding: 8px 8px;
            font-size: 23px;
            z-index: -1;
          }
          .date-label {
            display: block;
            position: absolute;
            left: 10px;
            top: -8px;
            background-color: $color-light-ghost-white;
            z-index: 100;
            font-size: 11px;
            padding: 0 4px;
          }
          .rdt .form-control {
            background-color: transparent;
            border: 1px solid $color-gray;
            height: 52px;
            &:focus {
              background-color: transparent;
              outline: 0;
              box-shadow: none;
            }
          }
        }
        &.date-show {
          font-size: 15px;
          text-transform: none;
        }
        &_labelBox {
          font-size: 12px;
          // color: $color-white;
          padding: 4px 8px;
          border-radius: 16px;
        }
      }
      &_form {
        padding: 12px;
        background-color: $color-white;
        border-radius: 6px;
        display: flex;
        padding-bottom: 16px;
        flex-direction: column;
        gap: 12px;
        box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
        margin-top: 12px;

        input {
          border: unset;
          padding: 4px 6px;
          border-radius: 4px;
          background: $color-light-gray;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          color: $color-gray;
          &::placeholder {
            color: $color-light-gray-bg;
          }
        }
        .form-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          color: $color-oldSilver;
          button {
            padding: 6px 16px;
            border: 1px solid $color-oldSilver;
            background: unset;
            border-radius: 24px;
          }
          .active {
            background-color: $color-primary-dark;
            color: $color-white;
          }
        }
        .error-msg {
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          color: $color-light-red;
        }
      }
      &.participants {
        .data_section_list_image {
          margin-left: -18px;
          &:first-child {
            margin-left: 0px;
          }
        }
      }
      &_count {
        display: inline-flex;
        background-color: $color-primary;
        color: $color-white;
        border-radius: 50px;
        max-width: 50px;
        min-width: 24px;
        min-height: 24px;
        padding: 1px 5px;
        align-items: center;
        justify-content: center;
      }
    }

    &_list,
    &_update {
      color: $color-gray;
      font-size: 15px;
      position: relative;
      border: none;
      background-color: transparent;
      button {
        border: none;
        background-color: transparent;
        &.order_id {
          padding: 0;
          text-align: justify;
          cursor: default;
        }
        &:disabled {
          user-select: none;
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
      .cancel-save-btn {
        background-color: $color-primary-dark;
        box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
        color: $color-white;
        padding: 3px 7px 4px;
        border-radius: 4px;
        display: block;
      }
      .save-btn {
        background-color: $color-primary-dark;
        box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
        color: $color-white;
        padding: 3px 7px 4px;
        border-radius: 4px;
        display: block;
        padding: 4px 16px;
      }
      .assign-btn {
        background-color: $color-primary-dark;
        box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
        color: $color-white;
        padding: 3px 7px 4px;
        border-radius: 4px;
        height: 31px;
        display: block;
      }
      .disabled {
        color: #828282;
      }
      &.DateTimeCalendar {
        position: absolute;
        right: 0;
        top: 15px;
      }
      .label-list-box {
        width: 328px;
        min-height: 170px;
        max-height: 470px;
        border-radius: 6px;
        border: 1px solid $color-light-light-gray;
        margin: 0;
        padding: 12px;
        position: absolute;
        top: 47px !important;
        .header-flex {
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $color-light-light-gray;
          padding: 2px 0px 12px;
          margin: 0 0 12px;
          .header-text {
            font-size: 17px;
            font-weight: 500;
            line-height: 20.57px;
          }
          .buttons {
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            column-gap: 8px;
          }
          .save-label-btn {
            border: none;
            background: none;
            font-size: 13px;
            font-weight: 400;
            line-height: 100%;
            text-align: center;
            padding: 4px 16px;
            height: 26px;
            background: $color-primary-dark;
            box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
            color: $color-white;
            border-radius: 6px;
          }
        }
        .search-label {
          max-width: 312px;
          width: 100%;
          margin: 0 16px 6px 0;
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          min-height: 42px;
          &-input {
            width: 100%;
            padding: 13px 33px 13px 12px;
            border-radius: 8px;
            border: 1px solid $color-light-light-gray;
            background: $color-white;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $color-gray;
          }
          .search-icon {
            position: absolute;
            right: 12px;
            top: 13px;
            cursor: pointer;
          }
        }
        .labelList {
          max-height: 192px;
          overflow-y: auto;
          margin: 8px 0 0;
          &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 4px $color-light-light-gray;
            border-radius: 8px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            -webkit-box-shadow: inset 0 0 4px $color-oldSilver;
            background-color: $color-oldSilver;
          }
          &-names {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
              list-style-type: none;
              padding: 8px 4px;
              margin: 0;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              column-gap: 10px;
              font-size: 15px;
              font-weight: 400;
              line-height: 18.15px;
              text-align: left;
              cursor: pointer;
              img {
                width: 16px;
              }
              .color-box {
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 4px;
              }
            }
          }
        }
        .create-newlable {
          width: 100%;
          border: none;
          padding: 13px 20px 13px;
          border-radius: 8px;
          font-size: 15px;
          font-weight: 400;
          line-height: 100%;
          text-align: center;
          margin: 18px 0 0;
          background-color: $color-light-light-gray;
        }
      }
    }

    &_list {
      &.flex-display {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 16px;
      }
    }
    .order-id {
      width: 100%;
    }
    .tool-center-link {
      input {
        font-size: 15px;
      }
      &-content {
        padding-top: 12px;
        font-size: 17px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        &-name {
          color: $color-primary;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }
}

.ticket_info_right-panel {
  &.disableClass {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .assignee-container,
  .tools-container {
    position: absolute;
    inset: 0px 0px auto auto;
    padding: 10px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 0px 4px 2px rgba(40, 43, 52, 0.12);
    width: 332px;
    min-height: 100px;
    top: 30px;
    right: -12px;
    z-index: 1;
    &__header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-gray-gray-5, $color-light-light-gray);
      padding-bottom: 10px;
      align-items: center;
      &1 {
        color: $color-gray;
        font-size: 17px;
        font-weight: 500;
        margin: 0;
      }
      &2 {
        font-size: 13px;
        color: $color-white;
        padding: 0 16px;
        cursor: pointer;
        border-radius: 5px;
        padding: 0px;
        margin: 0;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__input-area {
      padding: 10px 0 15px;
      position: relative;
      &__input {
        border-radius: 8px;
        border: 1px solid $color-light-light-gray;
        width: 100%;
        height: 42px;
        padding: 12px 32px 12px 12px;
        &:focus-visible {
          outline: unset;
        }
      }
      &__search {
        width: fit-content;
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
        img {
          width: 18px;
        }
        span {
          font-size: 18px;
        }
      }
    }
    &__tab {
      &__header {
        padding: 0 0 18px;
        overflow-x: auto;
        max-width: 312px;
        display: flex;
        column-gap: 12px;
        list-style-type: none;
        &::-webkit-scrollbar {
          height: 5px;
          &-track {
            border-radius: 6px;
            background-color: $color-light-light-gray;
          }
          &-thumb {
            border-radius: 6px;
            background-color: #828282;
            border: 2px solid transparent !important;
            background-clip: content-box;
          }
        }
        &__tab {
          a {
            padding: 10px 6px;
            span {
              font-size: 13px;
              font-weight: 400;
              color: #4f4f4f;
              white-space: nowrap;
            }
          }
          &.active a {
            border-bottom: 3px solid #082b45;
            span {
              font-weight: 500;
              color: #082b45;
            }
          }
        }
      }
      &__list {
        max-height: 306px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 8px;
          &-track {
            border-radius: 6px;
            background-color: $color-light-light-gray;
          }
          &-thumb {
            border-radius: 6px;
            background-color: #828282;
            border: 2px solid transparent !important;
            background-clip: content-box;
          }
        }
      }
    }
    &__assignee,
    &__tools {
      border-bottom: 1px solid #bdbdbd;
      &.border-bottom-none {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    .assigned:disabled {
      opacity: 0.5;
    }
    &__assignee,
    &__members,
    &__tools,
    &__products {
      padding-bottom: 20px;
      margin-bottom: 20px;
      &-name {
        color: $color-gray;
        font-size: 13px;
        font-weight: 500;
        margin: 0;
      }
      .assignee.list,
      .members.list,
      .tools.list,
      .products.list {
        display: flex;
        column-gap: 10px;
        align-items: center;
        padding: 10px 0 0 5px;
        justify-content: flex-start;
      }
      .list {
        &__checkbox {
          padding: 0px;
          img {
            width: 16px;
          }
          span {
            font-size: 16px;
          }
        }
        &__image {
          display: flex;
          width: 42px;
          height: 42px;
          border-radius: 50%;
          img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .no-image {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            text-align: center;
            border-radius: 50%;
            color: $color-white;
          }
          &__status {
            position: absolute;
            top: 22px;
            left: 22px;
          }
        }
        &__details {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: baseline;
          &__name {
            margin: 0;
            color: $color-gray;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          &__role {
            margin: 0;
            color: #828282;
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            text-align: left;
          }
        }
      }
    }
    &__members {
      margin: 0px;
      border: unset;
    }
    &__nomembers {
      margin: 20px;
      border: unset;
      color: $color-red;
    }
  }
  .active-user {
    border: 2px solid #27ae60;
  }
  .busy-user {
    border: 2px solid #eb5757;
  }
  .away-user {
    border: 2px solid #4f4f4f;
  }

  .ss-datepicker {
    .input-container input {
      border: none;
      padding: 0;
      margin: 0;
      font-size: 17px;
      text-transform: capitalize;
      outline: none;
    }
    .dropdown {
      width: 19rem !important;
      .date-picker-content,
      .EUCalendar-table-topControl tbody tr,
      .EUCalendar-dayNames,
      .EUCalendar-body,
      .picker-date__footer,
      .EUCalendar-menu,
      .EUCalendar-menu-cont,
      .EUCalendar-menu-today {
        background-color: $color-light-gray;
        color: $color-darkLiver;
      }
      .EUCalendar-dayNames div {
        font-weight: 500;
      }
      .EUCalendar-menu,
      .EUCalendar-menuYear {
        .EUCalendar-menu-cont {
          div:first-child table tbody tr:first-child td {
            background-color: $color-light-gray;
            color: $color-darkLiver;
          }
          .EUCalendar-menu-sep {
            display: none;
            background-color: $color-light-gray;
          }
        }
        .EUCalendar-menu-decade-table-cont {
          background-color: $color-light-gray;
        }
      }
      .EUCalendar-menu-close {
        font-size: 24px;
        right: 0;
        color: $color-darkLiver;
      }

      .picker-date__footer {
        padding: 5px 20px;
        .cancel-btn {
          color: $color-darkLiver;
          margin: unset;
        }
        .apply-btn {
          background-color: unset;
          border: unset;
          color: $color-darkLiver;
          min-width: unset;
        }
      }
    }
    caption {
      padding: 0px !important;
    }
    .date-picker-content {
      label {
        width: 100%;
      }
    }
  }
}

.data_section_watchlist {
  width: 100%;
  .data_section_watchlist_list {
    color: $color-gray;
    font-size: 15px;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 30px 0 8px 0;
    border-bottom: 1px solid $color-light-light-gray;
    margin: 0 0 25px;
    width: 100%;
  }
  .watchlist_search {
    padding: 0 10px;
    position: relative;
    .searchIcon {
      position: absolute;
      top: 13px;
      right: 20px;
      cursor: pointer;
    }
    .watchlist_search_input {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      border-radius: 8px;
      border: 1px solid $color-light-light-gray;
      width: 100%;
      height: 42px;
      color: $color-oldSilver;
      padding: 12px 32px 12px 12px;
      &:focus-visible {
        outline: unset;
      }
      &::placeholder {
        color: $color-light-light-gray-2;
      }
    }
  }
  .watchlist_dropdwon {
    max-height: 180px;
    overflow: auto;
    margin: 0 0 10px;
    &::-webkit-scrollbar {
      width: 9px;
      height: 8px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgb(167, 165, 165);
    }
    .watchlist_dropdwon_list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 8px 5px;
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        align-items: center;
        .list__checkbox {
          width: 16px;
          height: 16px;
          display: inline-flex;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .data_section_list_image .userNull-image {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 13px;
          line-height: 100%;
          text-align: center;
          color: $color-white;
          cursor: pointer;
        }
        .data_section_list_image {
          width: 40px;
          cursor: pointer;
        }
        .watchMember-details {
          display: inline-flex;
          flex-direction: column;
          row-gap: 4px;
          flex-wrap: nowrap;
          width: 70%;
          cursor: pointer;
          p {
            margin: 0;
            padding: 0;
            &.watchMember-name {
              font-size: 15px;
              font-weight: 500;
              line-height: 19.2px;
              text-align: left;
              color: $color-gray;
            }
            &.watchMember-job_title {
              color: $color-oldSilver;
              font-size: 13px;
              font-weight: 400;
              line-height: 16.8px;
            }
          }
        }
        &.no-user-found {
          font-weight: 500;
          font-size: 13px;
          line-height: 100%;
          text-align: center;
          color: $color-red;
          justify-content: center;
          margin: 10px 0 0;
        }
      }
    }
  }
  .watchlist_search_add-watchers {
    padding: 0 10px;
    .add-watchers-btn {
      display: block;
      width: 100%;
      background: $color-light-gray;
      padding: 8px;
      color: $color-gray;
      font-size: 15px;
      font-weight: 400;
      border: none;
      text-align: center;
      margin: 25px 0 0 0;
      border-radius: 8px;
      &.save {
        background: $color-primary-dark;
        color: $color-white;
      }
    }
  }
}

.data_section_participants {
  width: 100%;
  border-bottom: 1px solid $color-light-light-gray;
  padding-bottom: 16px;
  &.participants {
    .data_section_list_data {
      .data_section_list_image {
        margin-left: -18px;
        &:first-child {
          margin-left: 0px;
        }
      }
    }
  }
  .data_section_participants_list {
    color: $color-gray;
    font-size: 15px;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 30px 0 8px 0;
    border-bottom: 1px solid $color-light-light-gray;
    margin: 0 0 25px;
    width: 100%;
  }
  .participants_search {
    margin: 19px 0 10px;
    padding: 0 0px;
    position: relative;
    .searchIcon {
      position: absolute;
      top: 13px;
      right: 20px;
      cursor: pointer;
    }
    .participants_search_input {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      border-radius: 8px;
      border: 1px solid $color-light-light-gray;
      width: 100%;
      height: 42px;
      color: $color-oldSilver;
      padding: 12px 32px 12px 12px;
      &:focus-visible {
        outline: unset;
      }
      &::placeholder {
        color: $color-light-light-gray-2;
      }
    }
  }
  .participants_scroll {
    max-height: 300px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      background-color: rgb(167, 165, 165);
    }
    .participants_dropdwon {
      margin: 0;
      padding: 0 0 10px;
      &.border-bottom {
        margin: 10px 0 20px;
        border-bottom: 1px solid $color-white !important;
      }
      .participants_heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: $color-primary;
        margin: 0 0 10px;
        padding: 0 8px;
      }
      .participants_dropdwon_list {
        list-style-type: none;
        margin: 0;
        padding: 0 8px;
        li {
          list-style-type: none;
          margin: 0 0 0 0;
          padding: 8px 5px;
          display: flex;
          flex-direction: row;
          column-gap: 12px;
          align-items: center;
          &.user-selection {
            cursor: pointer;
          }
          &.disabled {
            user-select: none;
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
          }

          .list__checkbox {
            width: 16px;
            height: 16px;
            display: inline-flex;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .list__checkbox.crossMarkNotFill {
            width: 19px;
            height: 19px;
            display: inline-flex;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .data_section_list_image .userNull-image {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-weight: 500;
            font-size: 13px;
            line-height: 100%;
            text-align: center;
            color: $color-white;
          }
          .data_section_list_image {
            width: 40px;
          }
          .participants-details {
            display: inline-flex;
            flex-direction: column;
            row-gap: 4px;
            flex-wrap: nowrap;
            width: 70%;
            p {
              margin: 0;
              padding: 0;
              &.participants-name {
                font-size: 15px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: left;
                color: $color-gray;
              }
              &.participants-job_title {
                color: $color-oldSilver;
                font-size: 13px;
                font-weight: 400;
                line-height: 16.8px;
              }
            }
          }
          &.no-user-found {
            font-weight: 500;
            font-size: 13px;
            line-height: 100%;
            text-align: center;
            color: $color-red;
            justify-content: center;
            margin: 10px 0 0;
          }
        }
      }
    }
  }
  .participants_search_add-watchers {
    padding: 0 10px;
    .add-watchers-btn {
      display: block;
      width: 100%;
      background: $color-light-gray;
      padding: 8px;
      color: $color-gray;
      font-size: 15px;
      font-weight: 400;
      border: none;
      text-align: center;
      margin: 25px 0 0 0;
      border-radius: 8px;
      &.save {
        background: $color-primary-dark;
        color: $color-white;
      }
    }
  }
}
