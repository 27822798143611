.notification {
    position: relative;
    &__popup {
      position: absolute;
      top: 48px;
      right: -24px;
      width: 320px;
      min-height: 55px;
      border: 1px solid $color-light-light-gray;
      background: $color-white;
      border-radius: 4px;
      box-shadow: 0px 1px 2px 0px rgba(30, 25, 25, 0.08), 0px 2px 4px 0px rgba(30, 25, 25, 0.08), 0px 4px 8px 0px rgba(30, 25, 25, 0.08), 0px 8px 16px 0px rgba(30, 25, 25, 0.08), 0px 8px 32px 0px rgba(30, 25, 25, 0.08);
      // padding: 8px 0 4px;
      display: inline-flex;
      flex-direction: column;
      z-index: 900;
      align-items: flex-start;
      overflow: visible;
      // &:after,
      // &:before {
      //   bottom: 100%;
      //   left: 15%;
      //   border: solid transparent;
      //   content: " ";
      //   height: 0;
      //   width: 0;
      //   position: absolute;
      //   pointer-events: none;
      // }
      // &:after {
      //   border-color: rgba(255, 255, 255, 0);
      //   border-bottom-color: #ffffff;
      //   border-width: 8px;
      //   margin-left: -8px;
      // }
      // &:before {
      //   border-color: rgba(165, 175, 175, 0);
      //   border-bottom-color: transparent;
      //   border-width: 2px;
      //   margin-left: -10px;
      // }
      &--header{
        display: flex;
        padding: 18px 16px;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: $color-darkLiver;
        width: 100%;
        border-bottom: 1px solid #DFDCD8;
        margin: 0;
      }
      &--list {
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        color: $color-darkLiver;
        margin: 0;
        overflow: hidden;
        overflow-y: auto;
        max-height: 300px;
        width: 100%;
        &::-webkit-scrollbar {
          width: 9px;
          height: 8px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: rgb(167, 165, 165);
        }
        .unread{
          background: rgba(0, 173, 240, 0.08);
        }
        .notification_container {
          display: flex;
          column-gap: 14px;
          padding: 13px;
          cursor: pointer;
          // align-items: center;
          
          .no-img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: $color-light-light-gray;
          }
          &_image{
            position: relative;
            width: 32px;
            img{
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
          }
          &_details{
            position: relative;
            width: 87%;
            &_html{
              margin: 0;
              color: #1E1919;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              &_msg{
                margin: 0;
                color: #1E1919;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                max-height: 80px;
                min-height: 25px;
                p{
                  margin: 0;
                  font-size: 12px;
                  span{
                    font-size: 12px;
                  }
                }
              }
            }
            &_msg{
              margin: 0;
              color: #1E1919;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
            &_updatedOn{
              margin: 0;
              padding-top: 10px;
              color: rgba(82, 74, 62, 0.82);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
        .notification_container:not(:last-child){
          border-bottom: 1px solid var(--color-light-light-gray);
          width: 100%;
        }
      }
      &--more{
        display: flex;
        padding: 10px;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: var(--color-darkLiver);
        width: 100%;
        justify-content: end;
        button{
          border: unset;
          background-color: unset;
        }
      }
    }
}